import React, { useEffect, useMemo } from "react";
import {
  FormControl,
  Box,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import useRequestResource from "src/hooks/useRequestResource";

const initialValues = {
  subscription_type: "all",
  search: "",
};

export default function Filters({ onSubmit }) {
  const { getResourceList, resourceList } = useRequestResource({
    endpoint: "subscription-types",
  });
  const handleSubmit = (values) => {
    onSubmit(values);
  };

  const subscriptionTypesList = useMemo(() => {
    return [
      {
        value: "all",
        label: "All",
      },
    ].concat(
      resourceList.results.map((r) => {
        return {
          value: r.id,
          label: r.name,
          color: `#${r.color}`,
        };
      })
    );
  }, [resourceList.results]);
  const theme = useTheme();
  const isBelowMedium = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    getResourceList();
  }, [getResourceList]);
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      enableReinitialize
    >
      {(formik) => {
        return (
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <FormControl
                sx={{
                  width: isBelowMedium ? "100%" : "60%",
                  marginRight: (theme) => theme.spacing(1),
                  marginBottom: (theme) => theme.spacing(2),
                }}
                variant="outlined"
              >
                <TextField
                  size="small"
                  id="search"
                  label="Search"
                  type="search"
                  {...formik.getFieldProps("search")}
                />
              </FormControl>

              <FormControl
                sx={{
                  width: isBelowMedium ? "100%" : "20%",
                  marginRight: (theme) => theme.spacing(1),
                  marginBottom: (theme) => theme.spacing(2),
                }}
                variant="outlined"
              >
                <InputLabel id="subscription_type">
                  Subscription Type
                </InputLabel>
                <Select
                  labelId="subscription_type"
                  label="Subscription Type"
                  id="subscription_type"
                  size="small"
                  {...formik.getFieldProps("subscription_type")}
                >
                  {subscriptionTypesList.map((c) => {
                    return (
                      <MenuItem value={c.value} key={c.value}>
                        <div style={{ display: "flex" }}>{c.label}</div>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <Box sx={{ marginBottom: (theme) => theme.spacing(2) }}>
                <Button
                  type="submit"
                  size="medium"
                  variant="contained"
                  color="primary"
                >
                  Search
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
}

Filters.propTypes = {
  onSubmit: PropTypes.func,
};
