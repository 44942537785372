import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ListItemIcon,
  styled,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Avatar,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Link } from "react-router-dom";
import { EditOutlined, HowToReg, Visibility } from "@mui/icons-material";
import { blue, green, red, grey } from "@mui/material/colors";

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "unset",
  ":hover": {
    color: theme.palette.primary.main,
  },
}));

export default function MemberListItem({
  member,
  handleConfirmDelete,
  handleEdit,
  handleView,
  handleSubscribe,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  return (
    <Card elevation={3} sx={{ mb: 2 }}>
      <CardHeader
        sx={{
          pt: 1,
          pb: 1,
        }}
        titleTypographyProps={{
          variant: "subtitle2",
        }}
        action={
          <Box>
            <IconButton size="small" onClick={handleClick}>
              <MoreVertIcon
                fontSize="small"
                id={`members-card-action-${member.id}`}
                aria-controls={`members-card-menu-${member.id}`}
                // eslint-disable-next-line jsx-a11y/aria-proptypes
                aria-expanded={`member-card-menu-true-${
                  member.id ? true : false
                }`}
              />
            </IconButton>
            <Menu
              id={`members-card-action-menu-${member.id}`}
              aria-labelledby={`member-card-action-${member.id}`}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={handleSubscribe}>
                <ListItemIcon sx={{ color: green[500] }}>
                  <HowToReg fontSize="small" />
                </ListItemIcon>
              </MenuItem>
              <MenuItem onClick={handleView}>
                <ListItemIcon sx={{ color: grey }}>
                  <Visibility fontSize="small" />
                </ListItemIcon>
              </MenuItem>
              <MenuItem onClick={handleEdit}>
                <ListItemIcon sx={{ color: blue[500] }}>
                  <EditOutlined fontSize="small" />
                </ListItemIcon>
              </MenuItem>
              <MenuItem onClick={() => handleConfirmDelete(member.id)}>
                <ListItemIcon sx={{ color: red[500] }}>
                  <DeleteOutlineIcon fontSize="small" />
                </ListItemIcon>
              </MenuItem>
            </Menu>
          </Box>
        }
        title={
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            {member.image_url ? (
              <Avatar src={member.image_url} />
            ) : (
              <Avatar
                {...stringAvatar(member.firstname + " " + member.lastname)}
              />
            )}

            <StyledLink
              sx={{
                ml: 2,
              }}
              to={`/members/details/${member.id}`}
              key={"members-edit"}
            >
              {member.firstname} {member.lastname}
            </StyledLink>
          </Box>
        }
      />
      <CardContent sx={{ pt: 0.25, pb: 0.25 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="th">Phone Number</TableCell>
              <TableCell variant="td">{member.phone_number}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="th">Gender</TableCell>
              <TableCell variant="td">
                {member.gender === "M" ? "Male" : "Female"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="th">Weight</TableCell>
              <TableCell variant="td">{member.weight} KG</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="th">Branch</TableCell>
              <TableCell variant="td">{member.branch?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="th">Date Joined</TableCell>
              <TableCell variant="td">
                {new Date(member.date_created).toLocaleString("en-us", {
                  weekday: "long",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

MemberListItem.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.number,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    weight: PropTypes.number,
    phone_number: PropTypes.string,
    gender: PropTypes.string,
    date_registed: PropTypes.string,
    branch: PropTypes.object,
  }),
  handleConfirmDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  handleSubscribe: PropTypes.func,
};
