import React from "react";
import SubscriptionState from "./SubscriptionsState";
import SubscriptionsByType from "./SubscriptionsByType";
export default function Dashboard() {
  return (
    <div xs={12}>
      <SubscriptionState />
      <SubscriptionsByType />
    </div>
  );
}
