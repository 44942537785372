import React from "react";
import { createRoot } from "react-dom/client";
import CssBaseLine from "@mui/material/CssBaseline";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import { SnackbarProvider } from "notistack";
import BaseLayout from "./components/BaseLayout";
import SubscriptionTypes from "./pages/SubscriptionTypes";
import SubscriptionTypeDetails from "./pages/SubscriptionTypes/SubscriptionTypeDetails";

import SignIn from "./pages/Auth/SignIn";
import AuthContextProvider from "./contexts/AuthContextProvider";
import RequireAuth from "./components/RequireAuth";
import RequireNoAuth from "./components/RequireNoAuth";
import Members from "./pages/Members";
import MemberDetails from "./pages/Members/MemberDetails";
import Dashboard from "./pages/Dashboard";
import Subscriptions from "./pages/Subscriptions";
import SubscriptionDetails from "./pages/Subscriptions/SubscriptionDetails";
import SubscriptionViewDetails from "./pages/Subscriptions/SubscriptionViewDetails";
import Payments from "./pages/Payments";
import Branches from "./pages/Branch";
import BranchDetails from "./pages/Branch/BranchDetails";
import Messages from "./pages/Messages";
import MessagesDetails from "./pages/Messages/MessageDetails";
import Staff from "./pages/Staff";
import StaffDetails from "./pages/Staff/StaffDetails";
import Settings from "./pages/Settings";
import SubscriptionMessageTemplateDetails from "./pages/Settings/SubscriptionMessageTemplates/SubscriptionMessageTemplateDetails";
import SubscriptionMessageTemplate from "./pages/Settings/SubscriptionMessageTemplates";
import SubscriptionFees from "./pages/Settings/SubscriptionFees";
import SubscriptionFeeDetails from "./pages/Settings/SubscriptionFees/SubscriptionFeeDetails";
import SubscriptionExtras from "./pages/Settings/SubscriptionExtras";
import SubscriptionExtraDetails from "./pages/Settings/SubscriptionExtras/SubscriptionExtraDetails";
import ManagePassword from "./pages/Settings/ManagePassword";
import MembersDetailsView from "./pages/Members/MemberDetailsView";
import ImageView from "./pages/Members/ImageView";
export default function App() {
  return (
    <div>
      <CssBaseLine />
      <AuthContextProvider>
        <SnackbarProvider>
          <Router>
            <Box
              sx={{
                bgcolor: (theme) => theme.palette.background.default,
                minHeight: "100vh",
                width: "100%",
              }}
            >
              <Routes>
                <Route element={<RequireAuth />}>
                  <Route element={<BaseLayout />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/members" element={<Members />} />
                    <Route path="/members/create" element={<MemberDetails />} />
                    <Route
                      path="/members/edit/:id"
                      element={<MemberDetails />}
                    />
                    <Route
                      path="/members/details/:id"
                      element={<MembersDetailsView />}
                    />
                    <Route path="/members/image/:id" element={<ImageView />} />
                    <Route path="/branches" element={<Branches />} />
                    <Route
                      path="/branches/create"
                      element={<BranchDetails />}
                    />
                    <Route
                      path="/branches/edit/:id"
                      element={<BranchDetails />}
                    />
                    <Route
                      path="/subscription-types"
                      element={<SubscriptionTypes />}
                    />
                    <Route
                      path="/subscription-types/create"
                      element={<SubscriptionTypeDetails />}
                    />

                    <Route
                      path="/subscription-types/edit/:id"
                      element={<SubscriptionTypeDetails />}
                    />
                    <Route path="/subscriptions" element={<Subscriptions />} />

                    <Route
                      path="/subscriptions/create/:member"
                      element={<SubscriptionDetails />}
                    />
                    <Route
                      path="/subscriptions/edit/:id"
                      element={<SubscriptionDetails />}
                    />
                    <Route
                      path="/subscriptions/details/:id"
                      element={<SubscriptionViewDetails />}
                    />
                    <Route path="/messages" element={<Messages />} />
                    <Route
                      path="/messages/create"
                      element={<MessagesDetails />}
                    />
                    <Route path="/settings" element={<Settings />} />

                    <Route
                      path="/message-templates"
                      element={<SubscriptionMessageTemplate />}
                    />
                    <Route
                      path="/message-templates/create"
                      element={<SubscriptionMessageTemplateDetails />}
                    />
                    <Route
                      path="/message-templates/edit/:id"
                      element={<SubscriptionMessageTemplateDetails />}
                    />

                    <Route
                      path="/registration-fees"
                      element={<SubscriptionFees />}
                    />
                    <Route
                      path="/registration-fees/create"
                      element={<SubscriptionFeeDetails />}
                    />
                    <Route
                      path="/registration-fees/edit/:id"
                      element={<SubscriptionFeeDetails />}
                    />

                    <Route
                      path="/subscription-extras"
                      element={<SubscriptionExtras />}
                    />
                    <Route
                      path="/subscription-extras/create"
                      element={<SubscriptionExtraDetails />}
                    />
                    <Route
                      path="/subscription-extras/edit/:id"
                      element={<SubscriptionExtraDetails />}
                    />

                    <Route
                      path="/auth/change_password"
                      element={<ManagePassword />}
                    />

                    <Route path="/staff" element={<Staff />} />
                    <Route path="/staff/create" element={<StaffDetails />} />
                    <Route path="/staff/edit/:id" element={<StaffDetails />} />

                    <Route path="/payments" element={<Payments />} />
                  </Route>
                </Route>
                <Route element={<RequireNoAuth />}>
                  <Route path="/auth/login" element={<SignIn />} />
                </Route>
              </Routes>
            </Box>
          </Router>
        </SnackbarProvider>
      </AuthContextProvider>
    </div>
  );
}
const domNode = document.getElementById("root");
const root = createRoot(domNode);
root.render(<App />);
