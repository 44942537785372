import { useCallback, useState, useContext } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import formatHttpApiError from "src/helpers/formatHttpApiError";

import { AuthContext } from "src/contexts/AuthContextProvider";
import getCommonOptions from "src/helpers/axios/getCommonOptions";
import rootUrl from "src/helpers/axios/getAPIHostURL";

export default function useRequestAuth() {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(null);
  const [logoutPending, setLogoutPending] = useState(false);
  const { setIsAuthenticated } = useContext(AuthContext);

  const handleRequestError = useCallback(
    (err) => {
      const formattedError = formatHttpApiError(err);
      setError(formattedError);
      enqueueSnackbar(formattedError);
      setLoading(false);
    },
    [enqueueSnackbar, setLoading, setError]
  );
  const changePassword = useCallback(
    ({ current_password, new_password, re_new_password }, successCallback) => {
      setLoading(true);
      axios
        .post(
          `${rootUrl}/api/auth/users/set_password/`,
          {
            current_password,
            new_password,
            re_new_password,
          },
          getCommonOptions()
        )
        .then((data) => {
          enqueueSnackbar("Password changed successfully");
          setLoading(false);
          if (successCallback) {
            successCallback();
          }
        })
        .catch(handleRequestError);
    },
    [enqueueSnackbar, setLoading, handleRequestError]
  );

  const register = useCallback(
    ({ username, email, password }, successCallback) => {
      setLoading(true);
      axios
        .post(`${rootUrl}/api/auth/users/`, { username, email, password })
        .then((data) => {
          enqueueSnackbar(
            "Sign up is successful, you can now sign in with your credentials"
          );
          setLoading(false);
          if (successCallback) {
            successCallback();
          }
        })
        .catch(handleRequestError);
    },
    [enqueueSnackbar, setLoading, handleRequestError]
  );

  const login = useCallback(
    ({ username, password }) => {
      setLoading(true);
      axios
        .post(`${rootUrl}/api/auth/token/login`, {
          username,
          password,
        })
        .then((res) => {
          enqueueSnackbar("You have successfully logged in.");
          const { auth_token } = res.data;
          localStorage.setItem("authToken", auth_token);
          setIsAuthenticated(true);
          setLoading(false);
        })
        .catch(handleRequestError);
    },
    [enqueueSnackbar, setLoading, handleRequestError, setIsAuthenticated]
  );
  const logout = useCallback(() => {
    setLogoutPending(true);
    axios
      .post(`${rootUrl}/api/auth/token/logout`, {}, getCommonOptions())
      .then(() => {
        setLogoutPending(false);
        localStorage.removeItem("authToken");
        setIsAuthenticated(false);
      })
      .catch((err) => {
        setLogoutPending(false);
        handleRequestError(err);
      });
  }, [setLogoutPending, setIsAuthenticated, handleRequestError]);

  return {
    register,
    login,
    logout,
    changePassword,
    logoutPending,
    loading,
    error,
  };
}
