import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import {
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { red, blue } from "@mui/material/colors";

import { Link } from "react-router-dom";

export default function SubscriptionFeesTable({
  resourceList,
  handleConfirmDelete,
}) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return !matches ? (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Names</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="left">Price</TableCell>
            <TableCell align="left">Use in registration</TableCell>
            <TableCell align="left">Added on</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resourceList.results.map((data, i) => {
            return (
              <TableRow key={i}>
                <TableCell align="left">{`${data.name}`}</TableCell>
                <TableCell align="left">{data.description}</TableCell>
                <TableCell align="left">
                  {data.amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "KSH",
                  })}
                </TableCell>
                <TableCell align="left">
                  {data.use_in_registration ? "True" : "False"}
                </TableCell>
                <TableCell align="left">
                  {new Date(data.date_created).toLocaleString("en-us", {
                    weekday: "long",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  })}
                </TableCell>
                <TableCell align="right">
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Link
                      to={`/registration-fees/edit/${data.id}`}
                      key="registration-fees-edit"
                    >
                      <IconButton
                        sx={{
                          color: blue[500],
                        }}
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                    </Link>
                    <IconButton
                      size="large"
                      sx={{
                        color: red[500],
                      }}
                      onClick={() => handleConfirmDelete(data.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    resourceList.results.map((data, i) => {
      return (
        <TableContainer
          component={Paper}
          key={i}
          sx={{
            marginBottom: 5,
            minWidth: 270,
          }}
          size="small"
        >
          <Table size="small">
            <TableBody>
              <TableRow key={i}>
                <TableCell variant="head" align="right">
                  Name
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle1">{`${data.name}`}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" align="right">
                  Description
                </TableCell>
                <TableCell align="left">{data.description}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" align="right">
                  Amount
                </TableCell>
                <TableCell align="left">
                  {data.amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "KSH",
                  })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" align="right">
                  Use in registration?
                </TableCell>
                <TableCell align="left">
                  {data.use_in_registration ? "True" : "False"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" align="right">
                  Date added
                </TableCell>
                <TableCell align="left">
                  {new Date(data.date_created).toLocaleString("en-us", {
                    weekday: "long",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="right">
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Link
                      to={`/registration-fees/edit/${data.id}`}
                      key="registration-fees-edit"
                    >
                      <IconButton
                        sx={{
                          color: blue[500],
                        }}
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                    </Link>
                    <IconButton
                      size="large"
                      sx={{
                        color: red[500],
                      }}
                      onClick={() => handleConfirmDelete(data.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      );
    })
  );
}

SubscriptionFeesTable.propTypes = {
  resourceList: PropTypes.shape({
    results: PropTypes.array,
  }),
  handleConfirmDelete: PropTypes.func,
};
