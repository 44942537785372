import * as React from "react";

import { Paper, Box, Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
import SubscriptionMessagesTemplates from "src/pages/Settings/SubscriptionMessageTemplates";
import SubscriptionExtras from "./SubscriptionExtras";
import SubscriptionFees from "./SubscriptionFees";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Settings() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <Paper>
        <Box
          sx={{ maxWidth: { xs: 300, sm: 580 }, bgcolor: "background.paper" }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="scrollable force tabs example"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label="Message Templates" {...a11yProps(0)} />
            <Tab label="Subscription Extras" {...a11yProps(1)} />
            <Tab label="Registration Fees" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <SubscriptionMessagesTemplates />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <SubscriptionExtras />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <SubscriptionFees />
        </CustomTabPanel>
      </Paper>
    </Box>
  );
}

export default Settings;
