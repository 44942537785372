import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Pagination,
  Typography,
  Grid,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import Filters from "./Filters";
import useRequestResource from "src/hooks/useRequestResource";
import MemberListItem from "./MemberListItem";
// import ColorLabel from "./ColorLabel";
// import { green } from "@mui/material/colors";
const pageSize = 6;

export default function Members() {
  const { getResourceList, resourceList, deleteResource } = useRequestResource({
    endpoint: "members",
    resourceLabel: "Member",
  });
  const [open, setOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  const handleConfirmDelete = (id) => {
    setIdToDelete(id);
    setOpen(true);
  };

  const handleDeleteClose = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    setOpen(false);
    deleteResource(idToDelete);
  };
  const handleEdit = (id) => {
    navigate("/members/edit/" + id);
  };
  const handleView = (id) => {
    navigate("/members/details/" + id);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);

  const handleChangePagination = (event, value) => {
    const newQuery = { ...query, page: value };
    const newSearch = queryString.stringify(newQuery);
    navigate(`${location.pathname}?${newSearch}`);
  };

  const onSubmitSearch = (values) => {
    const { search, gender } = values;
    const newQuery = {
      search: search,
      gender: gender === "All" ? undefined : gender,
    };
    const newSearch = queryString.stringify(newQuery);
    navigate(`${location.pathname}?${newSearch}`);
  };

  useEffect(() => {
    getResourceList({ query: location.search });
  }, [getResourceList, location.search]);

  return (
    <div>
      <Dialog open={open} onClose={handleDeleteClose}>
        <DialogTitle>Are you sure you want to delete this member?</DialogTitle>
        <DialogActions>
          <Button onClick={handleDelete}>Yes</Button>
          <Button onClick={handleDeleteClose}>No</Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: 3,
          mt: 3,
        }}
      >
        <Button
          component={Link}
          variant="contained"
          color="primary"
          to="/members/create"
        >
          Create Member
        </Button>
      </Box>
      <Box>
        <Filters onSubmit={onSubmitSearch}></Filters>
      </Box>
      <Typography
        variant="subtitle1"
        sx={{
          marginLeft: (theme) => theme.spacing(1),
          marginBottom: (theme) => theme.spacing(2),
        }}
        color="text.primary"
      >
        {`Total Members: ${resourceList.count || 0}`}
      </Typography>
      <Grid item xs={12}>
        {resourceList.results.map((member, idx) => {
          return (
            <MemberListItem
              member={member}
              handleEdit={() => handleEdit(member.id)}
              handleView={() => handleView(member.id)}
              handleConfirmDelete={handleConfirmDelete}
              handleSubscribe={() => {
                navigate(`/subscriptions/create/${member.id}`);
              }}
              key={idx}
            />
          );
        })}
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Pagination
          color="primary"
          count={
            resourceList.count ? Math.ceil(resourceList.count / pageSize) : 0
          }
          page={query.page ? parseInt(query.page) : 1}
          onChange={handleChangePagination}
        />
      </Box>
    </div>
  );
}
