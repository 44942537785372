import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Button,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import useRequestResource from "src/hooks/useRequestResource";
function MembersDetailsView() {
  const { getResource, resource } = useRequestResource({
    endpoint: "members",
    resourceLabel: "Member",
  });

  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    getResource(id);
  }, [id, getResource]);

  useEffect(() => {
    if (resource) {
      console.log(resource);
    }
  }, [resource]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Box component={"div"}>
        <Card sx={{}}>
          <CardHeader title="Member Details" component="h1" />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              padding: 5,
            }}
          >
            <CardActionArea
              onClick={() => navigate(`/members/image/${resource.id}`)}
            >
              <CardMedia
                sx={{
                  height: 400,
                  width: "100%",
                  objectFit: "strech",
                }}
                component="img"
                alt={`${resource?.firstname} ${resource?.lastname}`}
                image={resource?.image_url}
              />
            </CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component={"div"}>
                {resource?.firstname} {resource?.lastname}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography component={"div"}>Gender</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>
                    {resource?.gender === "M" ? "Male" : "Female"}
                  </Typography>
                </Grid>
                {resource?.email && (
                  <>
                    <Grid item xs={4}>
                      <Typography component={"div"}>Email</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography>{resource?.email}</Typography>
                    </Grid>
                  </>
                )}

                <Grid item xs={4}>
                  <Typography component={"div"}>Phone</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{resource?.phone_number}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography component={"div"}>Weight</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{resource?.weight}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography component={"div"}>Date Joined</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{resource?.date_created}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Box>
          <CardActions>
            <Button onClick={() => navigate(`/members`)}>Back</Button>
            <Button onClick={() => navigate(`/members/edit/${resource?.id}`)}>
              Edit
            </Button>
          </CardActions>
        </Card>
      </Box>
      {/* <Box component={"div"}>
        <Card>
          <CardHeader title="Subscriptions" />
        </Card>
      </Box> */}
    </Box>
  );
}

export default MembersDetailsView;
