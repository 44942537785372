import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

import getCommonOptions from "src/helpers/axios/getCommonOptions";
import formatHttpApiError from "src/helpers/formatHttpApiError";
import StatChart from "./StatChart";
import axios from "axios";
import rootUrl from "src/helpers/axios/getAPIHostURL";
import Filters from "./Filters";

const generateChartData = (data = []) => {
  // console.log("Generating chart data");
  let chartData = {
    labels: [],
    datasets: [
      { data: [], backgroundColor: [], borderColor: [], borderWidth: 1 },
    ],
  };
  data.forEach((d) => {
    chartData.labels.push(d.name);
    chartData.datasets[0].data.push(d.count);
    chartData.datasets[0].backgroundColor.push(`#${d.color}`);
    chartData.datasets[0].borderColor.push(`#${d.color}`);
  });
  // console.log(chartData);
  return chartData;
};
const generateTableData = (data = []) => {
  // console.log("Generating tableData");
  const dataForTable = data.map((d) => {
    return {
      label: d.name,
      color: `#${d.color}`,
      count: d.count,
    };
  });
  // console.log(dataForTable);
  return dataForTable;
};

const baseApiUrl = `${rootUrl}/api/dashboard/subscriptions-by-subscription-type-distribution`;
export default function SubscriptionsByType() {
  const { enqueueSnackbar } = useSnackbar();
  const [queries, setQueries] = useState({
    is_expired: "All",
  });

  const [apiUrl, setApiUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (
      queries.is_expired === true ||
      queries.is_expired === false ||
      queries.is_expired === "All"
    ) {
      if (queries.is_expired === "All") {
        setApiUrl(`${baseApiUrl}`);
        return;
      }
      setApiUrl(`${baseApiUrl}?is_expired=${queries.is_expired}`);
      return;
    }
    setApiUrl(baseApiUrl);
  }, [queries]);
  useEffect(() => {
    if (!apiUrl) {
      return;
    }
    setIsLoading(false);
    axios
      .get(apiUrl, getCommonOptions())
      .then((res) => {
        setIsLoading(false);
        setChartData(generateChartData(res.data));
        setTableData(generateTableData(res.data));
      })
      .catch((error) => {
        setIsLoading(false);
        const formattedError = formatHttpApiError(error);
        enqueueSnackbar(formattedError);
      });
  }, [setIsLoading, setChartData, setTableData, apiUrl, enqueueSnackbar]);
  return (
    <StatChart
      tableData={tableData}
      chartData={chartData}
      isLoading={isLoading}
      filters={<Filters setQueries={setQueries} />}
    />
  );
}
