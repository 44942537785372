function getCommonOptions(content_type = "application/json") {
  const authToken = localStorage.getItem("authToken");
  if (!authToken) {
    return {};
  }
  return {
    headers: {
      Authorization: `Token ${authToken}`,
      "Content-Type": content_type,
    },
  };
}

export default getCommonOptions;
