import { useCallback, useState, useContext } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";

import formatHttpApiError from "src/helpers/formatHttpApiError";
import { LoadingOverlayResourceContext } from "src/components/LoadingOverlayResource";
import getCommonOptions from "src/helpers/axios/getCommonOptions";
import rootUrl from "src/helpers/axios/getAPIHostURL";

export default function useRequestResource({ endpoint, resourceLabel }) {
  const [resourceList, setResourceList] = useState({
    results: [],
  });

  const [resource, setResource] = useState(null);
  const [newResource, setNewResource] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(null);

  const loadingOverlay = useContext(LoadingOverlayResourceContext);
  const { setLoading } = loadingOverlay;

  const handleRequestResourceError = useCallback(
    (err) => {
      const formattedError = formatHttpApiError(err);
      setError(formattedError);
      setLoading(false);
      enqueueSnackbar(formattedError);
    },
    [enqueueSnackbar, setError, setLoading]
  );
  const getResourceList = useCallback(
    ({ query = "" } = {}) => {
      setLoading(true);

      axios
        .get(`${rootUrl}/api/${endpoint}/${query}`, getCommonOptions())
        .then((res) => {
          setLoading(false);
          if (res.data.results) {
            setResourceList(res.data);
          } else {
            setResourceList({ results: res.data });
          }
        })
        .catch(handleRequestResourceError);
    },
    [endpoint, handleRequestResourceError, setLoading]
  );

  const addResource = useCallback(
    (values, successCallback, content_type = "application/json") => {
      setLoading(true);
      axios
        .post(
          `${rootUrl}/api/${endpoint}/`,
          values,
          getCommonOptions(content_type)
        )
        .then((res) => {
          setNewResource(res.data);
          setLoading(false);
          enqueueSnackbar(`${resourceLabel} added.`);
          if (successCallback) {
            successCallback();
          }
        })
        .catch(handleRequestResourceError);
    },
    [
      endpoint,
      setNewResource,
      enqueueSnackbar,
      resourceLabel,
      handleRequestResourceError,
      setLoading,
    ]
  );

  const getResource = useCallback(
    (id) => {
      setLoading(true);
      axios
        .get(`${rootUrl}/api/${endpoint}/${id}/`, getCommonOptions())
        .then((res) => {
          setLoading(false);
          setResource(res.data);
        })
        .catch(handleRequestResourceError);
    },
    [endpoint, handleRequestResourceError, setLoading]
  );

  const updateResource = useCallback(
    (id, values, successCallback, content_type = "application/json") => {
      setLoading(true);
      axios
        .patch(
          `${rootUrl}/api/${endpoint}/${id}/`,
          values,
          getCommonOptions(content_type)
        )
        .then((res) => {
          setNewResource(res.data);
          setLoading(false);
          enqueueSnackbar(`${resourceLabel} updated.`);
          if (successCallback) {
            successCallback();
          }
        })
        .catch(handleRequestResourceError);
    },
    [
      endpoint,
      enqueueSnackbar,
      setNewResource,
      resourceLabel,
      handleRequestResourceError,
      setLoading,
    ]
  );

  const deleteResource = useCallback(
    (id) => {
      setLoading(true);
      axios
        .delete(`${rootUrl}/api/${endpoint}/${id}/`, getCommonOptions())
        .then(() => {
          setLoading(false);
          enqueueSnackbar(`${resourceLabel} deleted.`);
          const newResourceList = {
            results: resourceList.results.filter((r) => {
              return r.id !== id;
            }),
          };
          setResourceList(newResourceList);
        })
        .catch(handleRequestResourceError);
    },
    [
      endpoint,
      resourceList,
      enqueueSnackbar,
      resourceLabel,
      handleRequestResourceError,
      setLoading,
    ]
  );

  return {
    resourceList,
    getResourceList,
    addResource,
    getResource,
    resource,
    newResource,
    updateResource,
    deleteResource,
    error,
  };
}
