import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { useState } from "react";

import { Formik } from "formik";
import * as yup from "yup";
import useRequestAuth from "src/hooks/useRequestAuth";

const validationSchema = yup.object({
  current_password: yup.string().required("Old password is required"),
  new_password: yup.string().required("Password is required"),
  re_new_password: yup.string().required("retyped password is required."),
});

export default function ManagePassword() {
  const { changePassword } = useRequestAuth();
  const [initialValues, setInitialValues] = useState({
    current_password: "",
    new_password: "",
    re_new_password: "",
  });
  const handleSubmit = (values) => {
    changePassword(values);
    //console.log("Response from change password: ", res);
  };
  const handleChange = (name) => (event) => {
    setInitialValues({ ...initialValues, [name]: event.target.value });
  };
  //console.log("User auth context: ", user);
  return (
    <Paper
      sx={{
        borderRadius: (theme) => theme.spacing(0.5),
        boxShadow: (theme) => theme.shadows[5],
        padding: (theme) => theme.spacing(3),
      }}
    >
      <Typography variant="h6" mb={4}>
        Change password
      </Typography>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {(formik) => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="current_password"
                    label="Current Password"
                    type="password"
                    onChange={handleChange("current_password")}
                    value={initialValues.current_password}
                    error={
                      formik.touched.current_password &&
                      Boolean(formik.errors.current_password)
                    }
                    helperText={
                      formik.touched.current_password &&
                      formik.errors.current_password
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="new_password"
                    type="password"
                    label="New Password"
                    onChange={handleChange("new_password")}
                    value={initialValues.password}
                    error={
                      formik.touched.new_password &&
                      Boolean(formik.errors.new_password)
                    }
                    helperText={
                      formik.touched.new_password && formik.errors.new_password
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="re_new_password"
                    type="password"
                    label="Retype Password"
                    onChange={handleChange("re_new_password")}
                    value={initialValues.re_new_password}
                    // {...formik.getFieldProps("message")}
                    error={
                      formik.touched.re_new_password &&
                      Boolean(formik.errors.re_new_password)
                    }
                    helperText={
                      formik.touched.re_new_password &&
                      formik.errors.re_new_password
                    }
                  />
                </Grid>

                <Grid item>
                  <Box
                    sx={{
                      display: "flex",
                      margin: (theme) => theme.spacing(1),
                      marginTop: (theme) => theme.spacing(3),
                    }}
                  >
                    <Button
                      type="submit"
                      size="medium"
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Paper>
  );
}
