import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import {
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { red, blue } from "@mui/material/colors";

import { Link } from "react-router-dom";
import { fDateTime } from "src/utils/format-time";

export default function BranchesTable({ resourceList, handleConfirmDelete }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <TableContainer component={Paper}>
      {!matches ? (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Location</TableCell>
              <TableCell align="left">Created On</TableCell>
              <TableCell align="left">Created By</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resourceList.results.map((data, i) => {
              return (
                <TableRow key={i}>
                  <TableCell align="left">{data.name}</TableCell>
                  <TableCell align="left">{data.location}</TableCell>
                  <TableCell align="left">
                    {fDateTime(data.date_created)}
                  </TableCell>
                  <TableCell align="left">{data.created_by}</TableCell>
                  <TableCell align="right">
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Link to={`edit/${data.id}`} key="branch-edit">
                        <IconButton
                          sx={{
                            color: blue[500],
                          }}
                          size="large"
                        >
                          <EditIcon />
                        </IconButton>
                      </Link>
                      <IconButton
                        size="large"
                        sx={{
                          color: red[500],
                        }}
                        onClick={() => handleConfirmDelete(data.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        resourceList.results.map((data, i) => {
          return (
            <TableContainer
              component={Paper}
              key={i}
              sx={{
                marginBottom: 5,
                minWidth: 270,
              }}
              size="small"
            >
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell variant="head" align="right">
                      Name
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="subtitle1">{data.name}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head" align="right">
                      Location
                    </TableCell>
                    <TableCell align="left">{data.location}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head" align="right">
                      Created On
                    </TableCell>
                    <TableCell align="left">
                      {fDateTime(data.date_created)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head" align="right">
                      Created By
                    </TableCell>
                    <TableCell align="left">{data.created_by}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Link to={`edit/${data.id}`} key="branch-edit">
                          <IconButton
                            sx={{
                              color: blue[500],
                            }}
                            size="large"
                          >
                            <EditIcon />
                          </IconButton>
                        </Link>
                        <IconButton
                          size="large"
                          sx={{
                            color: red[500],
                          }}
                          onClick={() => handleConfirmDelete(data.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          );
        })
      )}
    </TableContainer>
  );
}

BranchesTable.propTypes = {
  resourceList: PropTypes.shape({
    results: PropTypes.array,
  }),
  handleConfirmDelete: PropTypes.func,
};
