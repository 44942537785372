import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Paper";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useRequestResource from "src/hooks/useRequestResource";
import { fDateTime } from "src/utils/format-time";
function SubscriptionDetailsView() {
  const { getResource, resource } = useRequestResource({
    endpoint: "subscriptions",
    resourceLabel: "Subscription",
  });

  const { id } = useParams();
  useEffect(() => {
    getResource(id);
  }, [id, getResource]);

  useEffect(() => {
    if (resource) {
      console.log(resource);
    }
  }, [resource]);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Card>
        <CardContent>
          <Box component={"div"} sx={{ marginBottom: "1em" }}>
            <Card>
              <CardHeader title="Subscription Information"></CardHeader>
              <CardContent>
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      {resource && (
                        <>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Subscription Type
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500 }}>
                              {resource.subscription_type}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Start Date
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500 }}>
                              {fDateTime(resource.start_date)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              End date
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500 }}>
                              {fDateTime(resource.end_date)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Amount
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500 }}>
                              Ksh {resource.full_amount}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" scope="row">
                              Expired
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500 }}>
                              {resource.is_expired === true ? "Yes" : "No"}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Box>

          <Box component={"div"}>
            <Card>
              <CardHeader title="Member Information"></CardHeader>

              <CardContent>
                {/* <Avatar
                  alt={`${resource?.member.firstname} ${resource?.member.lastname}`}
                  src={resource?.member.image_url}
                /> */}

                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      {resource && (
                        <>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Member Names
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500 }}>
                              {resource.member.firstname}{" "}
                              {resource.member.lastname}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" scope="row">
                              Gender
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500 }}>
                              {resource.member.gender === "M"
                                ? "Male"
                                : "Female"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Phone Number
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500 }}>
                              {resource.member.phone_number}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Box>
        </CardContent>
        <CardActions>
          <Button size="small">Learn More</Button>
        </CardActions>
      </Card>
    </Box>
  );
}

export default SubscriptionDetailsView;
