import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useRequestResource from "src/hooks/useRequestResource";

function ImageView() {
  const { getResource, resource } = useRequestResource({
    endpoint: "members",
    resourceLabel: "Member",
  });

  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    getResource(id);
  }, [id, getResource]);

  useEffect(() => {
    if (resource) {
      console.log(resource);
    }
  }, [resource]);
  return (
    <Card>
      <CardMedia
        sx={{
          width: "100%",
          objectFit: "strech",
        }}
        component="img"
        alt={`${resource?.firstname} ${resource?.lastname}`}
        image={resource?.image_url}
      />
      <CardActionArea>
        <CardActions>
          <Button onClick={() => navigate(`/members/details/${resource.id}`)}>
            Back
          </Button>
        </CardActions>
      </CardActionArea>
    </Card>
  );
}

export default ImageView;
