import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  Grid,
  TextField,
  Typography,
  Paper,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import * as yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import useRequestResource from "src/hooks/useRequestResource";
import ColorPicker from "src/components/ColorPicker";
export default function TrainingProgramDetails() {
  const { addResource, getResource, resource, updateResource } =
    useRequestResource({
      endpoint: "subscription-types",
      resourceLabel: "Subscription Type",
    });
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    subscription_message: "",
    cost: "",
    daily_cost: "",
    color: "",
    is_package_deal: false,
    duration: "",
    duration_type: "",
    number_of_people: "",
  });

  const validationSchema = yup.object({
    name: yup
      .string()
      .required("Name is required")
      .max(100, "Max length is 100"),
    cost: yup.number().required("Cost is required"),
    subscription_message: yup
      .string()
      .required("Subscription message is required"),
    color: yup
      .string()
      .required("Please select a color for this type")
      .max(10, "Max length is 10"),
  });

  const navigate = useNavigate();
  const handleOnChange = (name) => (event) => {
    if (name === "is_package_deal") {
      setInitialValues({
        ...initialValues,
        is_package_deal: !initialValues.is_package_deal,
      });
      return;
    }
    setInitialValues({ ...initialValues, [name]: event.target.value });
  };

  const handleSubmit = (values) => {
    const formattedValues = {
      ...values,
      color: values.color.substring(1),
    };
    //If is package deal is not checked
    if (!formattedValues.is_package_deal) {
      formattedValues["duration"] = undefined;
      formattedValues["duration_type"] = undefined;
      formattedValues["number_of_people"] = undefined;
    } else {
      //if package deal is checked
      formattedValues["daily_cost"] = "";
    }
    console.log("updating with: ");
    console.log(formattedValues);

    if (id) {
      updateResource(id, formattedValues, () => {
        navigate("/subscription-types");
      });
      return;
    }
    addResource(formattedValues, () => {
      navigate("/subscription-types");
    });
  };

  const { id } = useParams();

  useEffect(() => {
    if (id) getResource(id);
  }, [id, getResource]);

  useEffect(() => {
    if (resource) {
      setInitialValues({
        name: resource.name,
        description: resource.description,
        subscription_message: resource.subscription_message,
        color: `#${resource.color}`,
        cost: resource.cost,
        daily_cost: resource.daily_cost,
        is_package_deal: resource.is_package_deal,
        duration_type: resource.duration_type,
        duration: resource.duration,
        number_of_members: resource.number_of_members,
      });
      console.log(resource);
    }
  }, [resource]);

  return (
    <Paper
      sx={{
        borderRadius: "2px",
        boxShadow: (theme) => theme.shadows[5],
        padding: (theme) => theme.spacing(12, 4, 3),
      }}
    >
      <Typography variant="h6" mb={4}>
        {id ? "Edit Subscription Type" : "Create Subscription Type"}
      </Typography>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {(formik) => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="name"
                    label="Name"
                    value={initialValues.name}
                    onChange={handleOnChange("name")}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    id="description"
                    label="Description"
                    rows={2}
                    value={initialValues.description}
                    onChange={handleOnChange("description")}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    id="subscription_message"
                    label="Subscription Messge"
                    rows={3}
                    value={initialValues.subscription_message}
                    onChange={handleOnChange("subscription_message")}
                    error={
                      formik.touched.subscription_message &&
                      Boolean(formik.errors.subscription_message)
                    }
                    helperText={
                      formik.touched.subscription_message &&
                      formik.errors.subscription_message
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <ColorPicker
                    fullWidth
                    id="color"
                    value={initialValues.color}
                    label="Color"
                    onChange={(color) => {
                      setInitialValues({ ...initialValues, color: color.hex });
                    }}
                    error={formik.touched.color && Boolean(formik.errors.color)}
                    helperText={formik.touched.color && formik.errors.color}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="cost"
                    label="Cost"
                    value={initialValues.cost}
                    onChange={handleOnChange("cost")}
                    error={formik.touched.cost && Boolean(formik.errors.cost)}
                    helperText={formik.touched.cost && formik.errors.cost}
                  />
                </Grid>
                {!initialValues.is_package_deal && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="daily_cost"
                      label="Daily Cost"
                      value={initialValues.daily_cost}
                      onChange={handleOnChange("daily_cost")}
                      error={
                        formik.touched.daily_cost &&
                        Boolean(formik.errors.daily_cost)
                      }
                      helperText={
                        formik.touched.daily_cost && formik.errors.daily_cost
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl
                    sx={{ m: 3 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormLabel component="legend">
                      Create as a packaged deal?
                    </FormLabel>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={initialValues.is_package_deal}
                          onChange={handleOnChange("is_package_deal")}
                        />
                      }
                    />
                    <FormHelperText>
                      Packaged deals have predefiend duration and can be offered
                      to individuals or groups
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {initialValues.is_package_deal && (
                  <>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="duratation_type">
                          Duration Type
                        </InputLabel>
                        <Select
                          id="duration_type"
                          label="Duration Type"
                          value={initialValues.duration_type}
                          onChange={handleOnChange("duration_type")}
                          error={
                            formik.touched.duration_type &&
                            Boolean(formik.errors.duration_type)
                          }
                          helperText={
                            formik.touched.duration_type &&
                            formik.errors.duration_type
                          }
                        >
                          <MenuItem value={"D"}>Days</MenuItem>
                          <MenuItem value={"M"}>Months</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="duration"
                        label="Duration"
                        type="number"
                        value={initialValues.duration}
                        onChange={handleOnChange("duration")}
                        error={
                          formik.touched.duration &&
                          Boolean(formik.errors.duration)
                        }
                        helperText={
                          formik.touched.duration && formik.errors.duration
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="number_of_members"
                        label="Number of members"
                        type="number"
                        value={initialValues.number_of_members}
                        onChange={handleOnChange("number_of_members")}
                        error={
                          formik.touched.number_of_members &&
                          Boolean(formik.errors.number_of_members)
                        }
                        helperText={
                          formik.touched.number_of_members &&
                          formik.errors.number_of_members
                        }
                      />
                    </Grid>
                  </>
                )}
                <Grid item>
                  <Box
                    sx={{
                      display: "flex",
                      margin: (theme) => theme.spacing(1),
                      marginTop: (theme) => theme.spacing(3),
                    }}
                  >
                    <Button
                      component={Link}
                      to="/subscription-types"
                      size="medium"
                      variant="outlined"
                      sx={{ mr: 2 }}
                    >
                      Back
                    </Button>
                    <Button type="submit" size="medium" variant="contained">
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Paper>
  );
}
