import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import {
  Box,
  TextField,
  Grid,
  Paper,
  Typography,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
// import Divider from "@mui/material/Divider";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import useRequestResource from "src/hooks/useRequestResource";

const validationSchema = yup.object({
  name: yup.string().required("name is required").max(100, "Max length is 100"),
  description: yup
    .string()
    .required("Description is required")
    .max(1000, "Max length is 500"),
  price: yup.number().min(1).required("Price"),
  daily_price: yup.number().min(1).required("Daily Price"),
});

export default function SubscriptionExtraDetails() {
  const { resource, getResource, addResource, updateResource } =
    useRequestResource({
      endpoint: "subscription-extras",
      resourceLabel: "Subscription Extras",
    });

  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    price: "",
    daily_price: "",
    show_in_subscribe: false,
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const handleSubmit = (values) => {
    if (id) {
      updateResource(id, values, () => {
        navigate("/subscription-extras");
      });
      return;
    }
    addResource(values, () => {
      navigate("/subscription-extras");
    });
  };

  const handleChange = (name) => (event) => {
    let vals = initialValues;
    if (name === "show_in_subscribe") {
      setInitialValues({
        ...vals,
        [name]: !initialValues.show_in_subscribe,
      });
      return;
    }
    setInitialValues({
      ...vals,
      [name]: event.target.value,
    });
  };
  useEffect(() => {
    if (id) {
      getResource(id);
    }
  }, [getResource, id]);

  useEffect(() => {
    if (resource) {
      setInitialValues({
        name: resource.name,
        description: resource.description,
        price: resource.price,
        daily_price: resource.daily_price,
        show_in_subscribe: resource.show_in_subscribe,
      });
    }
  }, [resource]);

  // useEffect(() => {

  // }, []);

  return (
    <Paper
      sx={{
        borderRadius: (theme) => theme.spacing(0.5),
        boxShadow: (theme) => theme.shadows[5],
        padding: (theme) => theme.spacing(3),
      }}
    >
      <Typography variant="h6" mb={4}>
        {id ? "Edit Subscription Extra" : "Create Subscription Extra"}
      </Typography>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {(formik) => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="name"
                    label="Name"
                    onChange={handleChange("name")}
                    value={initialValues.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    id="description"
                    label="Description"
                    onChange={handleChange("description")}
                    rows={3}
                    value={initialValues.description}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="price"
                    label="Price"
                    onChange={handleChange("price")}
                    value={initialValues.price}
                    // {...formik.getFieldProps("message")}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    helperText={formik.touched.price && formik.errors.price}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="daily_price"
                    label="Daily Price"
                    onChange={handleChange("daily_price")}
                    value={initialValues.daily_price}
                    // {...formik.getFieldProps("message")}
                    error={
                      formik.touched.daily_price &&
                      Boolean(formik.errors.daily_price)
                    }
                    helperText={
                      formik.touched.daily_price && formik.errors.daily_price
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component={"fieldset"} variant="standard">
                    <FormLabel component={"legend"}>
                      Show in subscription form?
                    </FormLabel>
                    <FormControlLabel
                      label="Show"
                      control={
                        <Checkbox
                          checked={initialValues.show_in_subscribe}
                          onChange={handleChange("show_in_subscribe")}
                          id="show_in_subscribe"
                        />
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      display: "flex",
                      margin: (theme) => theme.spacing(1),
                      marginTop: (theme) => theme.spacing(3),
                    }}
                  >
                    <Button
                      component={Link}
                      to="/subscription-extras"
                      size="medium"
                      variant="outlined"
                      sx={{ mr: 2 }}
                    >
                      Back
                    </Button>

                    <Button
                      type="submit"
                      size="medium"
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Paper>
  );
}
