import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { TimeField } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Formik } from "formik";
import { useEffect, useState } from "react";
// import Divider from "@mui/material/Divider";
import { Link, useNavigate, useParams } from "react-router-dom";
import useRequestResource from "src/hooks/useRequestResource";
import * as yup from "yup";

const validationSchema = yup.object({
  name: yup.string().required("name is required").max(100, "Max length is 100"),
  description: yup
    .string()
    .required("Password is required")
    .max(500, "Max length is 500"),
  message: yup
    .string()
    .required("email is required")
    .max(500, "Max length is 500"),
  // is_pre_expire: yup.boolean().required("Before expire"),
  // is_post_expire: yup.boolean().required("Post expire is required"),
});

export default function SubscriptionMessageTemplateDetails() {
  const { resource, getResource, addResource, updateResource } =
    useRequestResource({
      endpoint: "subscription-message-templates",
      resourceLabel: "Subscription Message Template",
    });

  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    message: "",
    time_to_send: dayjs(Date()),
    is_sent_on_expire: true,
    is_sent_pre_expire: false,
    is_sent_post_expire: false,
    num_days: 0,
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const handleSubmit = (values) => {
    if (id) {
      updateResource(id, values, () => {
        navigate("/message-templates");
      });
      return;
    }
    addResource(values, () => {
      navigate("/message-templates");
    });
  };

  const handleChange = (name) => (event) => {
    let vals = initialValues;
    if (
      name === "is_sent_on_expire" ||
      name === "is_sent_post_expire" ||
      name === "is_sent_pre_expire"
    ) {
      vals = { ...vals, [name]: !vals[name] };
    } else {
      vals = { ...vals, [name]: event.target.value };
    }
    // console.log("Name is", name);
    // console.log("vals[name] is ", vals[name]);
    setInitialValues({
      ...vals,
    });
  };
  useEffect(() => {
    if (id) {
      getResource(id);
    }
  }, [getResource, id]);

  useEffect(() => {
    if (resource) {
      setInitialValues({
        name: resource.name,
        description: resource.description,
        message: resource.message,
        time_to_send: dayjs(resource.time_to_send),
        is_sent_on_expire: resource.is_sent_on_expire,
        is_sent_pre_expire: resource.is_sent_pre_expire,
        is_sent_post_expire: resource.is_sent_post_expire,
        phone_number: resource.phone_number,
        num_days: resource.num_days,
      });
    }
  }, [resource]);

  // useEffect(() => {
  //   console.log("Is time to send: " + initialValues.time_to_send);
  //   console.log("All of the initialValues: ", initialValues);
  // }, [initialValues]);

  return (
    <Paper
      sx={{
        borderRadius: (theme) => theme.spacing(0.5),
        boxShadow: (theme) => theme.shadows[5],
        padding: (theme) => theme.spacing(3),
      }}
    >
      <Typography variant="h6" mb={4}>
        {id ? "Edit Message Template" : "Create Message Template"}
      </Typography>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {(formik) => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="name"
                      label="Name"
                      onChange={handleChange("name")}
                      value={initialValues.name}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      id="description"
                      label="Description"
                      onChange={handleChange("description")}
                      value={initialValues.description}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      id="message"
                      label="Message"
                      onChange={handleChange("message")}
                      value={initialValues.message}
                      name="message"
                      rows={3}
                      // {...formik.getFieldProps("message")}
                      error={
                        formik.touched.message && Boolean(formik.errors.message)
                      }
                      helperText={
                        formik.touched.message && formik.errors.message
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <TimeField
                      label="Time to send"
                      value={initialValues.time_to_send}
                      onChange={(value) =>
                        setInitialValues({
                          ...initialValues,
                          time_to_send: value,
                        })
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <Divider component={Grid} />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component={"fieldset"} variant="standard">
                      <FormLabel component={"legend"}>
                        Message before or after expiry?
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          label="Send on expire"
                          control={
                            <Checkbox
                              checked={initialValues.is_sent_on_expire}
                              onChange={handleChange("is_sent_on_expire")}
                              id="is_sent_on_expire"
                            />
                          }
                        />
                        <FormControlLabel
                          label="Send before expire"
                          control={
                            <Checkbox
                              checked={initialValues.is_sent_pre_expire}
                              onChange={handleChange("is_sent_pre_expire")}
                              id="is_sent_pre_expire"
                              name="is_sent_pre_expire"
                            />
                          }
                        />

                        <FormControlLabel
                          label="Send after expire"
                          control={
                            <Checkbox
                              checked={initialValues.is_sent_post_expire}
                              onChange={handleChange("is_sent_post_expire")}
                              id="is_sent_post_expire"
                              name="is_sent_post_expire"
                            />
                          }
                        />
                      </FormGroup>
                      <FormHelperText>
                        Don't check any boxes to set expire message
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    {(initialValues.is_sent_pre_expire ||
                      initialValues.is_sent_post_expire) && (
                      <TextField
                        fullWidth
                        id="num_days"
                        label="Days prior or after"
                        onChange={handleChange("num_days")}
                        value={initialValues.num_days}
                        error={
                          formik.touched.num_days &&
                          Boolean(formik.errors.num_days)
                        }
                        helperText={
                          formik.touched.num_days && formik.errors.num_days
                        }
                      />
                    )}
                  </Grid>

                  <Grid item>
                    <Box
                      sx={{
                        display: "flex",
                        margin: (theme) => theme.spacing(1),
                        marginTop: (theme) => theme.spacing(3),
                      }}
                    >
                      <Button
                        component={Link}
                        to="/message-templates"
                        size="medium"
                        variant="outlined"
                        sx={{ mr: 2 }}
                      >
                        Back
                      </Button>

                      <Button
                        type="submit"
                        size="medium"
                        variant="contained"
                        color="primary"
                      >
                        Submit
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </form>
          );
        }}
      </Formik>
    </Paper>
  );
}
