import React, { useEffect, useState, useCallback } from "react";
import { Formik } from "formik";
import {
  Grid,
  TextField,
  Typography,
  Paper,
  Button,
  Box,
  Pagination,
} from "@mui/material";
import * as yup from "yup";
import queryString from "query-string";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useRequestResource from "src/hooks/useRequestResource";
import MemberListItem from "./MemberListItem";
import axios from "axios";
import getCommonOptions from "src/helpers/axios/getCommonOptions";
import MembersFilters from "./MembersFilters";

const validationSchema = yup.object({
  message: yup
    .string()
    .required("Name is required")
    .max(500, "Max length is 500"),
});
const pageSize = 6;

export default function MessagesDetails() {
  const { addResource } = useRequestResource({
    endpoint: "messages",
    resourceLabel: "Message",
  });

  const [members, setMembers] = useState([]);
  const [branches, setBranches] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [initialValues, setInitialValues] = useState({
    recipients: [],
    recipients_numbers: [],
    message: "",
  });

  const navigate = useNavigate();

  const handleSelectMember = (event, selectedMember) => {
    console.log("checkbox clicked with member id: ");
    const checked = event.target.checked;
    console.log("checkbox is checked? ", checked);
    if (checked) {
      const recipients = initialValues.recipients;
      const recipients_numbers = initialValues.recipients_numbers;
      recipients.push(selectedMember.id);
      recipients_numbers.push(selectedMember.phone_number);
      setInitialValues({ ...initialValues, recipients });
    } else {
      const recipients = initialValues.recipients;
      const recipients_numbers = initialValues.recipients_numbers;

      const idx = recipients.indexOf(selectedMember.id);
      console.log("Found selected member at index: ", idx);
      recipients.splice(idx, 1);
      recipients_numbers.splice(idx, 1);
      setInitialValues({ ...initialValues, recipients, recipients_numbers });
    }
    console.log(JSON.stringify(initialValues));
  };
  const handleSubmit = (values) => {
    const formattedValues = {
      ...values,
    };
    if (values.recipients_numbers.length === 0) {
      alert("No members selected. Select members");
      return;
    }

    console.log("Sending the following data: ");
    console.log(formattedValues);

    addResource(values, () => {
      navigate("/messages");
    });
  };
  const location = useLocation();
  const query = queryString.parse(location.search);

  const handleChangePagination = async (event, value) => {
    const newQuery = { ...query, page: value };
    const newSearch = queryString.stringify(newQuery);
    console.log("query values ", query);
    try {
      const res = await axios.get(
        `/api/members?${newSearch}`,
        getCommonOptions()
      );
      setMembers(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }

    navigate(`${location.pathname}?${newSearch}`);
  };
  const onSubmitSearch = async (values) => {
    const { search, gender, branch } = values;
    const newQuery = {
      search: search,
      gender: gender === "All" ? undefined : gender,
      branch: branch === "All" ? undefined : branch,
    };
    const newSearch = queryString.stringify(newQuery);
    try {
      const res = await axios.get(
        `/api/members?${newSearch}`,
        getCommonOptions()
      );
      setMembers(res.data);
    } catch (error) {
      console.log(error);
    }
    //navigate(`${location.pathname}?${newSearch}`);
  };

  const getBranches = useCallback(async () => {
    try {
      const resp = await axios.get("/api/branches", getCommonOptions());
      setBranches(resp.data.results);
    } catch (error) {
      console.log(error);
    }
  }, [setBranches]);

  const { getResourceList, resourceList } = useRequestResource({
    endpoint: "members",
    resourceLabel: "members",
  });

  useEffect(() => {
    getResourceList();
    getBranches();
  }, [getResourceList, getBranches]);

  useEffect(() => {
    setMembers(resourceList);
  }, [resourceList, setMembers]);

  return (
    <Paper
      sx={{
        borderRadius: "2px",
        boxShadow: (theme) => theme.shadows[5],
        padding: (theme) => theme.spacing(12, 4, 3),
      }}
    >
      <Typography variant="h6" mb={4}>
        Send Message
      </Typography>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {(formik) => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    id="message"
                    label="Message"
                    rows={3}
                    {...formik.getFieldProps("message")}
                    error={
                      formik.touched.message && Boolean(formik.errors.message)
                    }
                    helperText={formik.touched.message && formik.errors.message}
                  />
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      display: "flex",
                      margin: (theme) => theme.spacing(1),
                      marginTop: (theme) => theme.spacing(3),
                    }}
                  >
                    <Button
                      component={Link}
                      to="/messages"
                      size="medium"
                      variant="outlined"
                      sx={{ mr: 2 }}
                    >
                      Back
                    </Button>
                    <Button type="submit" size="medium" variant="contained">
                      Send
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
      <Box
        component={Paper}
        sx={{
          mt: (theme) => theme.spacing(5.5),
          pt: (theme) => theme.spacing(2.5),
        }}
      >
        <Box
          sx={{
            mt: (theme) => theme.spacing(2),
            mb: (theme) => theme.spacing(2),
            ml: 2,
          }}
        >
          <MembersFilters onSubmit={onSubmitSearch} branches={branches} />
        </Box>
        <Box>
          {members?.results?.map((member) => {
            return (
              <MemberListItem
                key={member.id}
                member={member}
                handleSelectMember={handleSelectMember}
                recipients={recipients}
                setRecipients={setRecipients}
              />
            );
          })}
        </Box>
        <Pagination
          color="primary"
          count={members.count ? Math.ceil(members.count / pageSize) : 0}
          page={query.page ? parseInt(query.page) : 1}
          onChange={handleChangePagination}
        />
      </Box>
    </Paper>
  );
}
