import React, { useState } from "react";
// import PropTypes from "prop-types";
import {
  ListItemIcon,
  styled,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Avatar,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Link } from "react-router-dom";
import { EditOutlined, SubscriptionsOutlined } from "@mui/icons-material";
import { blue, green, red } from "@mui/material/colors";

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "unset",
  ":hover": {
    color: theme.palette.primary.main,
  },
}));

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export default function StaffListItem({
  staff,
  handleConfirmDelete,
  handleEdit,
  handleSubscribe,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card elevation={3} sx={{ mb: 2 }}>
      <CardHeader
        sx={{
          pt: 1,
          pb: 1,
        }}
        titleTypographyProps={{
          variant: "subtitle2",
        }}
        action={
          <Box>
            <IconButton size="small" onClick={handleClick}>
              <MoreVertIcon
                fontSize="small"
                id={`staffs-card-action-${staff.id}`}
                aria-controls={`staffs-card-menu-${staff.id}`}
                // eslint-disable-next-line jsx-a11y/aria-proptypes
                aria-expanded={`staff-card-menu-true-${
                  staff.id ? true : false
                }`}
              />
            </IconButton>
            <Menu
              id={`staffs-card-action-menu-${staff.id}`}
              aria-labelledby={`staff-card-action-${staff.id}`}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={handleSubscribe}>
                <ListItemIcon sx={{ color: green[500] }}>
                  <SubscriptionsOutlined fontSize="small" />
                </ListItemIcon>
              </MenuItem>
              <MenuItem onClick={handleEdit}>
                <ListItemIcon sx={{ color: blue[500] }}>
                  <EditOutlined fontSize="small" />
                </ListItemIcon>
              </MenuItem>
              <MenuItem onClick={handleConfirmDelete}>
                <ListItemIcon sx={{ color: red[500] }}>
                  <DeleteOutlineIcon fontSize="small" />
                </ListItemIcon>
              </MenuItem>
            </Menu>
          </Box>
        }
        title={
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            {staff.image_url ? (
              <Avatar src={staff.image_url} />
            ) : (
              <Avatar
                {...stringAvatar(staff.first_name + " " + staff.last_name)}
              />
            )}
            <StyledLink
              sx={{
                ml: 2,
              }}
              to={`/staff/edit/${staff.id}`}
              key={"staffs-edit"}
            >
              {staff.username} {staff.email}
            </StyledLink>
          </Box>
        }
      />
      <CardContent sx={{ pt: 0.25, pb: 0.25 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="th">Phone Number</TableCell>
              <TableCell variant="td">{staff?.phone_number}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="th">Is Admin</TableCell>
              <TableCell variant="td">
                {staff?.is_superuser ? "True" : "False"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="th">Is Active</TableCell>
              <TableCell variant="td">
                {staff?.is_active ? "True" : "False"}{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="th">Branch</TableCell>
              <TableCell variant="td">{staff?.branch?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="th">Date Joined</TableCell>
              <TableCell variant="td">
                {new Date(staff?.date_joined).toLocaleString("en-us", {
                  weekday: "long",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

// StaffListItem.propTypes = {
//   staff: PropTypes.shape({
//     id: PropTypes.number,
//     username: PropTypes.string,
//     email: PropTypes.string,
//     password: PropTypes.string,
//     firstname: PropTypes.string,
//     lastname: PropTypes.string,
//     weight: PropTypes.number,
//     phone_number: PropTypes.string,
//     gender: PropTypes.string,
//     date_registed: PropTypes.string,
//     branch: PropTypes.string,
//   }),
//   handleConfirmDelete: PropTypes.func,
//   handleEdit: PropTypes.func,
//   handleSubscribe: PropTypes.func,
// };
