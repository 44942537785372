import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import axios from "axios";

import { Grid, Box } from "@mui/material";
import { Assignment } from "@mui/icons-material";
import { Check } from "@mui/icons-material";
import { AssignmentLate } from "@mui/icons-material";

import formatHttpApiError from "src/helpers/formatHttpApiError";
import getCommonOptions from "src/helpers/axios/getCommonOptions";
import rootUrl from "src/helpers/axios/getAPIHostURL";
import StatCard from "./StatCard";

export default function SubscriptionState() {
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionStateStats, setSubscriptionStateStats] = useState({
    expired: null,
    active: null,
  });

  const { enqueueSnackbar } = useSnackbar();
  // console.log(`rootUrl inside SubscriptionState index file: ${rootUrl}`);
  useEffect(() => {
    setIsLoading(true);

    axios
      .get(`${rootUrl}/api/dashboard/subscriptions-state`, getCommonOptions())
      .then((res) => {
        const { data } = res;
        //console.log(data);
        if (data) {
          const stats = { expired: 0, active: 0 };
          data.forEach((dt) => {
            if (dt.is_expired) {
              stats.expired = dt.count;
            } else {
              stats.active = dt.count;
            }
          });
          // console.log(stats);
          setSubscriptionStateStats(stats);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        const formattedError = formatHttpApiError(e);
        enqueueSnackbar(formattedError);
        setIsLoading(false);
      });
  }, [enqueueSnackbar, setIsLoading]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        mb: (theme) => theme.spacing(2),
      }}
    >
      <Grid container spacing={3}>
        <StatCard
          title="Total Subscriptions"
          value={
            (subscriptionStateStats.expired || 0) +
            (subscriptionStateStats.active || 0)
          }
          loading={isLoading}
          icon={<Assignment fontSize="small" />}
        />

        <StatCard
          title="Active Subscriptions"
          value={subscriptionStateStats.active || 0}
          loading={false}
          icon={<Check fontSize="small" />}
        />
        <StatCard
          title="Expired Subscriptions"
          value={subscriptionStateStats.expired || 0}
          loading={false}
          icon={<AssignmentLate fontSize="small" />}
        />
      </Grid>
    </Box>
  );
}
