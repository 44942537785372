import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Grid,
  Pagination,
} from "@mui/material";

import { Link, useNavigate, useLocation } from "react-router-dom";
import Filters from "./Filters";

import useRequestResource from "src/hooks/useRequestResource";
import queryString from "query-string";
import SubscriptionFeesTable from "./SubscriptionFeesTable";

const pageSize = 6;

export default function SubscriptionFees() {
  const { getResourceList, resourceList, deleteResource } = useRequestResource({
    endpoint: "registration-fees",
    resourceLabel: "Registration Fee",
  });
  const [open, setOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  const handleConfirmDelete = (id) => {
    setIdToDelete(id);
    setOpen(true);
  };

  const handleDeleteClose = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    setOpen(false);
    deleteResource(idToDelete);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);

  const handleChangePagination = (event, value) => {
    const newQuery = { ...query, page: value };
    const newSearch = queryString.stringify(newQuery);
    navigate(`${location.pathname}?${newSearch}`);
  };

  const onSubmitSearch = (values) => {
    const { search } = values;
    const newQuery = {
      search: search,
    };
    const newSearch = queryString.stringify(newQuery);
    navigate(`${location.pathname}?${newSearch}`);
  };

  useEffect(() => {
    getResourceList({ query: location.search });
  }, [getResourceList, location.search]);
  //console.log(resourceList);
  return (
    <div>
      <Dialog open={open} onClose={handleDeleteClose}>
        <DialogTitle>
          Are you sure you want to delete this registration fee?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDelete}>Yes</Button>
          <Button onClick={handleDeleteClose}>No</Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: 4,
        }}
      >
        <Button
          component={Link}
          variant="contained"
          color="primary"
          to="/registration-fees/create"
        >
          Create registration fee
        </Button>
      </Box>
      <Box>
        <Filters onSubmit={onSubmitSearch} />
      </Box>
      <Typography
        variant="subtitle1"
        sx={{
          marginLeft: (theme) => theme.spacing(1),
          marginBottom: (theme) => theme.spacing(2),
        }}
        color="text.primary"
      >
        {`Total Registration Fees: ${resourceList.count || 0}`}
      </Typography>
      <Grid item xs={12}>
        <SubscriptionFeesTable
          resourceList={resourceList}
          handleConfirmDelete={handleConfirmDelete}
        />
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Pagination
          color="primary"
          count={
            resourceList.count ? Math.ceil(resourceList.count / pageSize) : 0
          }
          page={query.page ? parseInt(query.page) : 1}
          onChange={handleChangePagination}
        />
      </Box>
    </div>
  );
}
