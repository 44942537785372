import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { spacing } from "@mui/system";
import { CloudUpload } from "@mui/icons-material";
import { Formik } from "formik";
import { useEffect, useState, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useRequestResource from "src/hooks/useRequestResource";
import * as yup from "yup";
const genderData = {
  M: {
    label: "Male",
    name: "M",
  },
  F: {
    label: "Female",
  },
};
const genderDataList = Object.keys(genderData).map((key) => ({
  key,
  ...genderData[key],
  value: key,
}));
const validationSchema = yup.object({
  firstname: yup
    .string()
    .required("Firstname is required")
    .max(100, "Max length is 100"),
  lastname: yup
    .string()
    .required("Lastname is required")
    .max(100, "Max length is 100"),
  phone_number: yup
    .string()
    .required("Phone number is required")
    .max(20, "Max length is 20"),
  gender: yup
    .string()
    .required("Gender is required")
    .max(6, "Max length is six"),
  branch: yup
    .string()
    .required("Branch is required")
    .max(100, "Max length is six"),
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

export default function MemberDetails() {
  const [open, setOpen] = useState(false);
  const [membershipFee, setMembershipFee] = useState({
    fees: [],
    total: 0,
  });

  const regFees = useRequestResource({
    endpoint: "registration-fees",
  });

  const getFeeResourceList = regFees.getResourceList;
  const feesResourceList = regFees.resourceList;

  const { resource, getResource, addResource, updateResource } =
    useRequestResource({
      endpoint: "members",
      resourceLabel: "Member",
    });
  const { getResourceList, resourceList } = useRequestResource({
    endpoint: "branches-no-pg",
    resourceLabel: "Branch",
  });

  const [member, setMember] = useState({
    firstname: "",
    lastname: "",
    phone_number: "",
    gender: "",
    weight: "",
    branch: "",
    image_url: "",
    membership_fees: {
      registration_fees: [],
      fees_total: 0,
    },
  });

  const [memberImage, setMemberImage] = useState();
  const initialValues = member;

  const getMembershipFeesAndTotal = useMemo(() => {
    const feesObj = feesResourceList?.results;
    const fees = feesObj.map((fee) => {
      return fee.amount;
    });
    const fees_obj = feesObj.map((fee) => {
      return { id: fee.id, amount: fee.amount };
    });
    let totalFee = 0;
    if (fees && fees.length > 0) {
      totalFee = fees.reduce((total, fee) => {
        return total + fee;
      });
    }
    return {
      fees: fees_obj,
      total: totalFee,
    };
  }, [feesResourceList]);

  const navigate = useNavigate();
  const { id } = useParams();

  const classes = useStyles();

  const handleRegistrationFeeClose = () => {
    setOpen(false);
  };

  const confirmSubmit = () => {
    setOpen(false);
    const values = {
      ...member,
      membership_fees: {
        fees_total: membershipFee.total,
        registration_fees: membershipFee.fees,
      },
    };
    const formData = new FormData();
    for (const key in values) {
      formData.append(key, values[key]);
    }

    addResource(
      formData,
      () => {
        navigate("/members");
      },
      "multipart/form-data"
    );
  };

  const handleChange = (name) => (event) => {
    setMember({ ...member, [name]: event.target.value });
  };

  const handleImageChange = (event) => {
    const newData = { ...initialValues };
    newData["image_url"] = event.target.files[0];
    setMember(newData);
    setMemberImage(URL.createObjectURL(event.target.files[0]));

    // const imgOut = document.getElementById("member_img");
    // imgOut.src = URL.createObjectURL(event.target.files[0]);
    // imgOut.style.width = "200px";
    // imgOut.style.height = "200px";
    // imgOut.style.margin = "5px 0";
  };

  const handleSubmit = (values) => {
    if (id && resource) {
      if (values?.image_url && typeof values.image_url === "string")
        values = { ...values, image_url: undefined };
      updateResource(
        id,
        values,
        () => {
          navigate("/members");
        },
        "multipart/form-data"
      );
      return;
    }
    setOpen(!open);
  };

  useEffect(() => {
    if (id) {
      getResource(id);
    }
  }, [getResource, id]);

  useEffect(() => {
    if (resource) {
      setMember({
        firstname: resource.firstname,
        lastname: resource.lastname,
        phone_number: resource.phone_number,
        gender: resource.gender,
        weight: resource.weight,
        image_url: resource.image_url,
        branch: resource.branch.id,
      });
      setMemberImage(resource.image_url);
    }
  }, [resource]);

  useEffect(() => {
    getResourceList();
  }, [getResourceList]);

  useEffect(() => {
    getFeeResourceList();
  }, [getFeeResourceList]);

  useEffect(() => {
    const { fees, total } = getMembershipFeesAndTotal;
    setMembershipFee({ fees, total });
  }, [getMembershipFeesAndTotal]);
  return (
    <div>
      {!id ? (
        <Dialog open={open} onClose={handleRegistrationFeeClose}>
          <DialogTitle>Please collect the below registration fees.</DialogTitle>
          <DialogContent>
            <List>
              {feesResourceList?.results.map((fee, idx) => {
                return (
                  <ListItem key={idx}>
                    <ListItemText>
                      {fee.name} -{" "}
                      {fee.amount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "KSH",
                      })}
                    </ListItemText>
                  </ListItem>
                );
              })}
            </List>
            <Typography
              variant="h6"
              sx={{
                padding: (theme) => {
                  theme.spacing(1);
                },
              }}
            >
              Total: {membershipFee.total}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={confirmSubmit}>Complete Registration</Button>
            <Button onClick={handleRegistrationFeeClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      ) : (
        ""
      )}

      <Paper
        sx={{
          borderRadius: (theme) => theme.spacing(0.5),
          boxShadow: (theme) => theme.shadows[5],
          padding: (theme) => theme.spacing(3),
        }}
      >
        <Typography variant="h6" mb={4}>
          {id ? "Edit Member" : "Create Member"}
        </Typography>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {(formik) => {
            return (
              <form onSubmit={formik.handleSubmit} id="member_form">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="firstname"
                      label="Firstname"
                      // {...formik.getFieldProps("firstname")}
                      value={member.firstname}
                      onChange={handleChange("firstname")}
                      error={
                        formik.touched.firstname &&
                        Boolean(formik.errors.firstname)
                      }
                      helperText={
                        formik.touched.firstname && formik.errors.firstname
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="lastname"
                      label="Lastname"
                      // {...formik.getFieldProps("lastname")}
                      value={member.lastname}
                      onChange={handleChange("lastname")}
                      error={
                        formik.touched.lastname &&
                        Boolean(formik.errors.lastname)
                      }
                      helperText={
                        formik.touched.lastname && formik.errors.lastname
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="phone_number"
                      label="Phone Number"
                      value={member.phone_number}
                      onChange={handleChange("phone_number")}
                      // {...formik.getFieldProps("phone_number")}
                      error={
                        formik.touched.phone_number &&
                        Boolean(formik.errors.phone_number)
                      }
                      helperText={
                        formik.touched.phone_number &&
                        formik.errors.phone_number
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="weight"
                      label="Weight"
                      value={member.weight}
                      onChange={handleChange("weight")}
                      // {...formik.getFieldProps("weight")}
                      error={
                        formik.touched.weight && Boolean(formik.errors.weight)
                      }
                      helperText={formik.touched.weight && formik.errors.weight}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                      }}
                      error={
                        formik.touched.gender && Boolean(formik.errors.gender)
                      }
                    >
                      <InputLabel id="gender-label">Gender</InputLabel>
                      <Select
                        fullWidth
                        labelId="gender"
                        label="Gender"
                        id="gender"
                        value={member.gender}
                        onChange={handleChange("gender")}
                      >
                        {Array.isArray(genderDataList) &&
                          genderDataList.map((p) => {
                            return (
                              <MenuItem value={p.value} key={p.value}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box sx={{ ml: 1 }}>{p.label}</Box>
                                </Box>
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <FormHelperText>
                        {formik.touched.gender && formik.errors.gender}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                      }}
                      error={
                        formik.touched.branch && Boolean(formik.errors.branch)
                      }
                    >
                      <InputLabel id="branch-label">Branch</InputLabel>
                      <Select
                        fullWidth
                        labelId="branch"
                        label="Branch"
                        id="branch"
                        value={member.branch}
                        onChange={handleChange("branch")}
                      >
                        {Array.isArray(resourceList.results) &&
                          resourceList.results.map((branch, idx) => {
                            return (
                              <MenuItem value={branch.id} key={idx}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box sx={{ ml: 1 }}>{branch.name}</Box>
                                </Box>
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <FormHelperText>
                        {formik.touched.branch && formik.errors.branch}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.root}>
                      <TextField
                        fullWidth
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        className={classes.input}
                        onChange={handleImageChange}
                        error={
                          formik.touched.image_url &&
                          Boolean(formik.errors.image_url)
                        }
                        helperText={
                          formik.touched.image_url && formik.errors.image_url
                        }
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          startIcon={<CloudUpload />}
                        >
                          Upload User Image
                        </Button>
                      </label>
                    </div>
                    <div>
                      {memberImage && (
                        <img
                          src={memberImage}
                          width={200}
                          height={200}
                          alt=""
                          style={{
                            marginTop: 5,
                          }}
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item>
                    <Box
                      sx={{
                        display: "flex",
                        margin: (theme) => theme.spacing(1),
                        marginTop: (theme) => theme.spacing(3),
                      }}
                    >
                      <Button
                        component={Link}
                        to="/members"
                        size="medium"
                        variant="outlined"
                        sx={{ mr: 2 }}
                      >
                        Back
                      </Button>

                      <Button
                        type="submit"
                        size="medium"
                        variant="contained"
                        color="primary"
                      >
                        Submit
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </Paper>
    </div>
  );
}
