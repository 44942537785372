import { Visibility } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { blue, red } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { fDateTime } from "src/utils/format-time";

export default function SubscriptionsTable({
  resourceList,
  handleConfirmDelete,
}) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return !matches ? (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Member names</TableCell>
            <TableCell align="left">Subscription Type</TableCell>
            <TableCell align="left">Start Date</TableCell>
            <TableCell align="left">End Date</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resourceList.results.map((data, i) => {
            return (
              <TableRow key={i}>
                <TableCell align="left">{`${data.member.firstname} ${data.member.lastname}`}</TableCell>
                <TableCell align="left">{data.subscription_type}</TableCell>
                <TableCell align="left">{fDateTime(data.start_date)}</TableCell>
                <TableCell align="left">{fDateTime(data.end_date)}</TableCell>
                <TableCell align="right">
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Link
                      to={`/subscriptions/details/${data.id}`}
                      key="subscription-type-edit"
                    >
                      <IconButton
                        sx={{
                          color: blue[500],
                        }}
                        size="large"
                      >
                        <Visibility />
                      </IconButton>
                    </Link>
                    <IconButton
                      size="large"
                      sx={{
                        color: red[500],
                      }}
                      onClick={() => handleConfirmDelete(data.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    resourceList.results.map((data, i) => {
      return (
        <TableContainer
          component={Paper}
          key={i}
          sx={{
            marginBottom: 5,
            minWidth: 270,
          }}
          size="small"
        >
          <Table size="small">
            <TableBody>
              <TableRow key={i}>
                <TableCell variant="head" align="right">
                  Member
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle1">{`${data.member.firstname} ${data.member.lastname}`}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" align="right">
                  Subscription Type
                </TableCell>
                <TableCell align="left">{data.subscription_type}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" align="right">
                  Start Date
                </TableCell>
                <TableCell align="left">{fDateTime(data.start_date)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" align="right">
                  End Data
                </TableCell>
                <TableCell align="left">{fDateTime(data.end_date)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="right">
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Link
                      to={`/subscriptions/details/${data.id}`}
                      key="subscription-type-edit"
                    >
                      <IconButton
                        sx={{
                          color: blue[500],
                        }}
                        size="large"
                      >
                        <Visibility />
                      </IconButton>
                    </Link>
                    <IconButton
                      size="large"
                      sx={{
                        color: red[500],
                      }}
                      onClick={() => handleConfirmDelete(data.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      );
    })
  );
}

SubscriptionsTable.propTypes = {
  resourceList: PropTypes.shape({
    results: PropTypes.array,
  }),
  handleConfirmDelete: PropTypes.func,
};
