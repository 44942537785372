import React, { useState } from "react";
import PropTypes from "prop-types";
import { Checkbox, Card, CardHeader, CardContent, Box } from "@mui/material";

export default function MemberListItem({
  member,
  handleSelectMember,
  recipients,
  setRecipients,
}) {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Card elevation={3} sx={{ mb: 2 }}>
      <CardHeader
        sx={{
          pt: 1,
          pb: 1,
        }}
        titleTypographyProps={{
          variant: "subtitle2",
        }}
        title={
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Checkbox
              sx={{ padding: (theme) => `0 ${theme.spacing(0.5)} 0 0` }}
              checked={isChecked}
              onClick={(e) => {
                setIsChecked(!isChecked);
                handleSelectMember(e, member);
              }}
            />
            <Box key={"tasks-edit"}>
              {member.firstname} {member.lastname}
            </Box>
          </Box>
        }
      />
      <CardContent sx={{ pt: 0.25, pb: 0.25 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {member.phone_number}
        </Box>
      </CardContent>
    </Card>
  );
}

MemberListItem.propTypes = {
  member: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    phone_number: PropTypes.string,
    gender: PropTypes.string,
    id: PropTypes.number,
  }),
  handleSelectMember: PropTypes.func,
  recipients: PropTypes.array,
  setRecipients: PropTypes.func,
};
