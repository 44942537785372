import React from "react";
import { AuthContext } from "src/contexts/AuthContextProvider";
import { Navigate, Outlet } from "react-router-dom";
export default function RequireNoAuth() {
  const { isAuthenticated, user } = React.useContext(AuthContext);

  if (isAuthenticated === null) {
    return <div>Loading.....</div>;
  }

  if (
    isAuthenticated === true &&
    user?.type &&
    (user?.type.toUpperCase() === "ADMIN" ||
      user?.type.toUpperCase() === "STAFF")
  ) {
    return <Navigate to="/" />;
  }
  if (
    isAuthenticated === true &&
    user?.type &&
    user?.type.toUpperCase() === "TRAINER"
  ) {
    return <Navigate to="/members" />;
  }
  if (isAuthenticated === true && !user?.type && user?.is_superuser === true) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
}
