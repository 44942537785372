import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import {
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { red, blue } from "@mui/material/colors";

import { Link } from "react-router-dom";

export default function SubscriptionMessageTemplatesTable({
  resourceList,
  handleConfirmDelete,
}) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return !matches ? (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Names</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="left">Message</TableCell>
            <TableCell align="left">Send on expire</TableCell>
            <TableCell align="left">Send pre-expire</TableCell>
            <TableCell align="left">Send post-expire</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resourceList.results.map((data, i) => {
            return (
              <TableRow key={i}>
                <TableCell align="left">{`${data.name}`}</TableCell>
                <TableCell align="left">{data.description}</TableCell>
                <TableCell align="left">{data.message}</TableCell>
                <TableCell align="left">
                  {data.is_sent_on_expire ? "True" : "False"}
                </TableCell>
                <TableCell align="left">
                  {data.is_sent_pre_expire ? "True" : "False"}
                </TableCell>
                <TableCell align="left">
                  {data.is_sent_post_expire ? "True" : "False"}
                </TableCell>
                <TableCell align="right">
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Link
                      to={`/message-templates/edit/${data.id}`}
                      key="message-templates-edit"
                    >
                      <IconButton
                        sx={{
                          color: blue[500],
                        }}
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                    </Link>
                    <IconButton
                      size="large"
                      sx={{
                        color: red[500],
                      }}
                      onClick={() => handleConfirmDelete(data.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    resourceList.results.map((data, i) => {
      return (
        <TableContainer
          component={Paper}
          key={i}
          sx={{
            marginBottom: 5,
            minWidth: 270,
          }}
          size="small"
        >
          <Table size="small">
            <TableBody>
              <TableRow key={i}>
                <TableCell variant="head" align="right">
                  Name
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle1">{`${data.name}`}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" align="right">
                  Description
                </TableCell>
                <TableCell align="left">{data.description}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" align="right">
                  Message
                </TableCell>
                <TableCell align="left">{data.message}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" align="right">
                  Send On-Expire
                </TableCell>
                <TableCell align="left">
                  {data.is_pre_expire ? "True" : "False"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" align="right">
                  Send Pre-Expire
                </TableCell>
                <TableCell align="left">
                  {data.is_pre_expire ? "True" : "False"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" align="right">
                  Send Post-Expire
                </TableCell>
                <TableCell align="left">
                  {data.is_post_expire ? "True" : "False"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="right">
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Link
                      to={`/message-templates/edit/${data.id}`}
                      key="message-templates-edit"
                    >
                      <IconButton
                        sx={{
                          color: blue[500],
                        }}
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                    </Link>
                    <IconButton
                      size="large"
                      sx={{
                        color: red[500],
                      }}
                      onClick={() => handleConfirmDelete(data.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      );
    })
  );
}

SubscriptionMessageTemplatesTable.propTypes = {
  resourceList: PropTypes.shape({
    results: PropTypes.array,
  }),
  handleConfirmDelete: PropTypes.func,
};
