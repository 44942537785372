import React from "react";
import {
  FormControl,
  Box,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const initialValues = {
  gender: "All",
  branch: "All",
  search: "",
};

export default function MembersFilters({ onSubmit, branches }) {
  const handleSubmit = (values) => {
    onSubmit(values);
  };

  const genderList = [
    {
      value: "All",
      label: "All",
    },
    {
      value: "M",
      label: "Male",
    },
    {
      value: "F",
      label: "Female",
    },
  ];
  const theme = useTheme();
  const isBelowMedium = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      enableReinitialize
    >
      {(formik) => {
        return (
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <FormControl
                sx={{
                  width: isBelowMedium ? "100%" : "40%",
                  marginRight: (theme) => theme.spacing(1),
                  marginBottom: (theme) => theme.spacing(2),
                }}
                variant="outlined"
              >
                <TextField
                  size="small"
                  id="search"
                  label="Search"
                  type="search"
                  {...formik.getFieldProps("search")}
                />
              </FormControl>

              <FormControl
                sx={{
                  width: isBelowMedium ? "100%" : "20%",
                  marginRight: (theme) => theme.spacing(1),
                  marginBottom: (theme) => theme.spacing(2),
                }}
                variant="outlined"
              >
                <InputLabel id="gender-label">Gender</InputLabel>
                <Select
                  labelId="gender-label"
                  label="Gender"
                  id="filter-gender"
                  size="small"
                  {...formik.getFieldProps("gender")}
                >
                  {genderList.map((c) => {
                    return (
                      <MenuItem value={c.value} key={c.value}>
                        <div style={{ display: "flex" }}>{c.label}</div>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl
                sx={{
                  width: isBelowMedium ? "100%" : "20%",
                  marginRight: (theme) => theme.spacing(1),
                  marginBottom: (theme) => theme.spacing(2),
                }}
                variant="outlined"
              >
                <InputLabel id="branch-label">Branch</InputLabel>
                <Select
                  labelId="branch-label"
                  label="Branch"
                  id="branch"
                  size="small"
                  {...formik.getFieldProps("branch")}
                >
                  <MenuItem value="All">
                    <div style={{ display: "flex" }}>All</div>
                  </MenuItem>
                  {branches?.map((b, idx) => {
                    return (
                      <MenuItem value={b.id} key={idx}>
                        <div style={{ display: "flex" }}>{b.name}</div>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <Box sx={{ marginBottom: (theme) => theme.spacing(2) }}>
                <Button
                  type="submit"
                  size="medium"
                  variant="contained"
                  color="primary"
                >
                  Search
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
}

MembersFilters.propTypes = {
  onSubmit: PropTypes.func,
  branch: PropTypes.object,
};
