import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useRequestResource from "src/hooks/useRequestResource";
import * as yup from "yup";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

const paymentTypeData = {
  CASH: {
    label: "Cash",
  },
  MPESA: {
    label: "M-pesa",
  },
  CARD: {
    label: "Card",
  },
};
const paymentTypeDataList = Object.keys(paymentTypeData).map((key) => ({
  key,
  ...paymentTypeData[key],
  value: key,
}));
const validationSchema = yup.object({
  // member: yup
  //   .string()
  //   .required("Member is required")
  //   .max(100, "Max length is 100"),
  subscription_type: yup
    .string()
    .required("Subscription type is required")
    .max(100, "Max length is 100"),
  amount_paid: yup.number().positive().required("Amount paid is required"),
  months: yup.number().positive().required("Months is required"),
  start_date: yup.date().required("Start date is required"),
  payment_mode: yup
    .string()
    .max(5, "Max length is 6")
    .required("Payment type is required"),
  // reference_number: yup
  //   .string()
  //   .max(100, "Max length is 100")
  //   .required("Reference number is required"),
});

export default function SubscriptionDetails() {
  const { resourceList, getResourceList } = useRequestResource({
    endpoint: "subscription-types-no-pg",
  });
  const { getResource, resource } = useRequestResource({
    endpoint: "members",
  });
  const { addResource } = useRequestResource({
    endpoint: "subscriptions",
    resourceLabel: "Subscription",
  });

  const subscriptionExtrasRequestResource = useRequestResource({
    endpoint: "subscription-extras-no-pg",
  });
  const subExtraGetResourceList =
    subscriptionExtrasRequestResource.getResourceList;
  const subExtraResourceList = subscriptionExtrasRequestResource.resourceList;

  const navigate = useNavigate();
  const [showRefNumField, setShowRefNumField] = useState(false);

  const [subscription, setSubscription] = useState({
    member: "",
    member_names: "",
    reference_number: "",
    subscription_type: "",
    amount_paid: 0.0,
    full_amount: 0.0,
    is_monthly_subscription: true,
    months: "",
    allow_credit: true,
    start_date: dayjs(new Date()),
    payment_mode: "",
    end_date: "",
    payment: {
      subscription: "",
      reference_number: "",
      amount_paid: 0.0,
      amount_due: 0.0,
      payment_mode: "",
      date_due: "",
      paid_in_full: "",
    },
    subscription_extras: [],
    subscription_extras_w_details: [],
  });

  const initialValues = subscription;
  const [subscriptionType, setSubscriptionType] = useState({});
  const { member, id } = useParams();
  const [subscriptionExtras, setSubscriptionExtras] = useState([]);

  const calculateSubscriptionExtraAmount = (
    subscription_extras,
    duration,
    is_monthly_subscription
  ) => {
    let sub_cost = 0;
    console.log(": ", !duration, typeof duration, parseInt(duration));
    if (!duration || parseInt(duration) < 1) duration = 1;
    if (subscription_extras.length < 1) return 0;
    //Set price to monthly price if monthly subscription is selected.
    if (is_monthly_subscription) {
      const sub_cost_array = subscription_extras.map((val) => {
        return parseFloat(val.price);
      });
      sub_cost = sub_cost_array.reduce((total, value) => {
        return total + parseFloat(value);
      });
    }

    //Set price to daily price if daily subscription is set.
    if (!is_monthly_subscription) {
      const sub_cost_array = subscription_extras.map((val) => {
        return parseFloat(val.daily_price);
      });
      sub_cost = sub_cost_array.reduce((total, value) => {
        return total + parseFloat(value);
      });
    }
    // console.log("subscription extras: duration", duration);

    // console.log("Subtotal for subscription extras: ", sub_cost * duration);
    return sub_cost * duration;
  };

  const calculateSubscriptionAmount = (
    subscription_type,
    duration,
    is_monthly_subscription,
    subscription_extras
  ) => {
    //Destructure important data from subpcription_type and
    //create variable that will be returned by this function
    console.log(subscription_type);
    const { cost, daily_cost, is_package_deal } = subscription_type;
    let amounts = { full_amount: 0.0, amount_paid: 0.0 };

    //Check whether subscription type is a package deal and execute the
    //following code block and exit if true.
    if (is_package_deal) {
      //Destruct duration type and duration from subscription_type
      const { duration, duration_type } = subscription_type;
      const is_monthly_subscription = duration_type === "M" ? true : false;
      let sub_cost = 0;
      if (subscription_extras.length > 0) {
        //Because subscription_type is package deal, we are going to set
        // duration from subscription_type values and not those in subscriptions variable
        sub_cost = calculateSubscriptionExtraAmount(
          subscription_extras,
          duration,
          is_monthly_subscription
        );
        console.log("Inside subscription_extra > 0");
        console.log("Value sub_cost: ", sub_cost);
      }

      amounts = {
        full_amount: parseFloat(cost) + parseFloat(sub_cost),
        amount_paid: parseFloat(cost) + parseFloat(sub_cost),
      };

      return amounts;
    }

    //Check whether duration is set and the value is greater than zero,
    //if not, set duration to value 1 so calcuations do not break if I
    //receive null, undefined, string or zero
    if (!duration || parseInt(duration) < 1) duration = 1;

    //If monthly subscription is selected, set amounts to the selected subscription type,
    //If subscription type is null, then we assign the value 0
    console.log(
      "subscription type in calculateSubscriptionAmount",
      subscription_type
    );
    if (is_monthly_subscription) {
      amounts = {
        full_amount: parseFloat(
          (cost ? parseFloat(cost) : 0) * parseInt(duration)
        ),
        amount_paid: parseFloat(
          (cost ? parseFloat(cost) : 0) * parseInt(duration)
        ),
      };
    }
    //If day subscription is selected
    if (!is_monthly_subscription) {
      amounts = {
        full_amount: parseFloat(
          (daily_cost ? parseFloat(daily_cost) : 0) * parseInt(duration)
        ),
        amount_paid: parseFloat(
          (daily_cost ? parseFloat(daily_cost) : 0) * parseInt(duration)
        ),
      };
    }

    let sub_cost = 0;
    if (subscription_extras.length > 0) {
      sub_cost = calculateSubscriptionExtraAmount(
        subscription_extras,
        duration,
        is_monthly_subscription
      );
    }

    amounts = {
      full_amount: amounts.full_amount + sub_cost,
      amount_paid: amounts.amount_paid + sub_cost,
    };

    return amounts;
  };
  const handleFocus = (name) => (event) => {
    setSubscription({ ...subscription, [name]: event.target.value });
  };
  const handleOnChange = (name) => (event) => {
    if (name === "payment_mode") {
      setSubscription({
        ...subscription,
        payment_mode: event.target.value,
      });
      if (event.target.value === "CASH") {
        setShowRefNumField(false);
      } else {
        setShowRefNumField(true);
      }
      return;
    }
    if (name === "subscription_type") {
      const subscription_type = resourceList.results.find((cur) => {
        if (cur.id === event.target.value) {
          return cur;
        }
        return null;
      });

      setSubscriptionType(subscription_type);

      //If subscription type is not undefined or null
      if (subscription_type) {
        //If the selected subscription type is a packege deal, set duration
        //information from subscription_type and not from form.
        const is_monthly_subscription =
          subscription_type.duration_type === "M" ? true : false;
        if (subscription_type.is_package_deal) {
          //This code is executed if the selected package is a package deal.
          setSubscription({
            ...subscription,
            is_monthly_subscription,
            months: subscription_type.duration,
            subscription_type: subscription_type.id,
            ...calculateSubscriptionAmount(
              subscription_type,
              subscription_type.duration,
              is_monthly_subscription,
              subscriptionExtras
            ),
          });
          return;
        }
        //This sets subscription state variable. Some values come from
        //destructuring what is returned from of calculateSubscriptionAmount
        //This code is executed only if selected subscription type is not a package deal.
        setSubscription({
          ...subscription,
          subscription_type: subscription_type.id,
          ...calculateSubscriptionAmount(
            subscription_type,
            subscription.months,
            subscription.is_monthly_subscription,
            subscriptionExtras
          ),
        });
      }
      return;
    }
    if (name === "is_monthly_subscription") {
      const is_monthly_subscription =
        event.target.value === "true" ? true : false;
      setSubscription({
        ...subscription,
        is_monthly_subscription,
        ...calculateSubscriptionAmount(
          subscriptionType,
          subscription.months,
          is_monthly_subscription,
          subscriptionExtras
        ),
      });

      return;
    }
    if (name === "months") {
      // console.log("Months on change is being triggered programatically");
      // if (subscriptionType && subscriptionType.is_package_deal) {
      //   const { duration, duration_type } = subscriptionType;
      //   const is_monthly_subscription = duration_type === "M" ? true : false;
      //   setSubscription({
      //     ...subscription,
      //     months: event.target.value,
      //     ...calculateSubscriptionAmount(
      //       subscriptionType,
      //       duration,
      //       is_monthly_subscription,
      //       subscriptionExtras
      //     ),
      //   });
      //   return;
      // }
      setSubscription({
        ...subscription,
        months: event.target.value,
        ...calculateSubscriptionAmount(
          subscriptionType,
          parseInt(event.target.value),
          subscription.is_monthly_subscription,
          subscriptionExtras
        ),
      });

      return;
    }

    if (name === "reference_number") {
      setSubscription({
        ...subscription,
        reference_number: event.target.value,
      });
      return;
    }
    if (name === "subscription_extras") {
      const subscriptionExtraId = parseInt(event.target.value);
      const subscription_extra = subExtraResourceList?.results.find(
        (subExtra) => {
          return subExtra.id === subscriptionExtraId;
        }
      );

      //Check whether the id passed as value of the checkbox is in the arrays
      //where I store values when a subscription extra is checked.
      const subExtraIdFound = subscription.subscription_extras?.find((id) => {
        return id === subscriptionExtraId;
      });
      const subExtraFound = subscriptionExtras?.find((subExtra) => {
        return subExtra === subscription_extra;
      });

      //If a subscription extra just got checked.
      if (event.target.checked) {
        //If the value is already in the arrays re
        if (subExtraFound && subExtraIdFound) return;
        //create local variables so I can push the just checked subscription extra
        //to the necessary arrays.
        const local_subscription_extra_ids = subscription.subscription_extras
          ? subscription.subscription_extras
          : [];
        local_subscription_extra_ids.push(subscriptionExtraId);
        const local_subscription_extras = subscriptionExtras
          ? subscriptionExtras
          : [];
        local_subscription_extras.push(subscription_extra);
        setSubscriptionExtras(local_subscription_extras);
        setSubscription({
          ...subscription,
          subscription_extras: local_subscription_extra_ids,
          ...calculateSubscriptionAmount(
            subscriptionType,
            subscription.months,
            subscription.is_monthly_subscription,
            local_subscription_extras
          ),
        });

        return;
      }

      //Subscription extra just got unchecked
      if (!event.target.checked) {
        //If the value is already in the arrays return from this event.
        if (subExtraIdFound && subExtraFound) {
          const subExtraIdIdx =
            subscription.subscription_extras.indexOf(subExtraIdFound);
          const subExtraIdx = subscriptionExtras.indexOf(subExtraFound);
          //If we find that subscription_extra id, we remove it from the array
          if (subExtraIdIdx !== -1) {
            const subscription_extras_ids = subscription.subscription_extras;
            subscription_extras_ids.splice(subExtraIdIdx, 1);
            const subscription_extras = subscriptionExtras;
            subscription_extras.splice(subExtraIdx, 1);
            const { full_amount, amount_paid } = calculateSubscriptionAmount(
              subscriptionType,
              subscription.months,
              subscription.is_monthly_subscription,
              subscription_extras
            );

            setSubscription({
              ...subscription,
              subscription_extras: subscription_extras_ids,
              full_amount,
              amount_paid,
            });
            setSubscriptionExtras(subscription_extras);
          }
        }
      }

      return;
    }
    // if (name === "allow_credit") {
    //   if (!initialValues.allow_credit) {
    //     setSubscription({
    //       ...subscription,
    //       paid_amount: initialValues.full_amount,
    //     });

    //   }
    //   setSubscription({
    //     ...subscription,
    //     allow_credit: !initialValues.allow_credit,
    //   });
    //   return
    // }
    setSubscription({ ...subscription, [name]: event.target.value });
  };

  const handleSubmit = (values) => {
    let post_subscription = values;

    if (values.payment_mode === "CASH") {
      post_subscription["reference_number"] = uuidv4();
      setSubscription({
        ...subscription,
        reference_number: uuidv4(),
      });
    } else {
      setSubscription({
        ...subscription,
        reference_number: values.reference_number,
      });
    }
    const start_date = new Date(Date.parse(values.start_date));
    const date_due = new Date();
    const end_date = new Date();
    date_due.setDate(start_date.getDate() + 5);
    end_date.setMonth(start_date.getMonth() + values.months);

    const paidInFull =
      initialValues.full_amount - initialValues.amount_paid < 1;
    post_subscription = {
      ...post_subscription,
      start_date: start_date,
      end_date: end_date,
      member: resource.id,
      payment: {
        reference_number: values.reference_number,
        amount_paid: values.amount_paid,
        amount_due: values.full_amount - values.amount_paid,
        payment_mode: values.payment_mode,
        date_due: paidInFull ? null : date_due,
        paid_in_full: paidInFull,
      },
    };

    if (initialValues.amount_paid < parseFloat(initialValues.full_amount)) {
      alert("amount is less than cost");
    } else if (
      initialValues.amount_paid > parseFloat(initialValues.full_amount)
    ) {
      alert(
        "Amount being paid is more than amount owed. Please correct the amount"
      );
    }

    addResource(post_subscription, () => {
      navigate("/subscriptions");
    });
  };

  useEffect(() => {
    getResourceList();
  }, [getResourceList]);
  useEffect(() => {
    if (member) {
      getResource(member);
    }
  }, [member, getResource]);

  const getSubscription = useMemo(() => {
    console.log("subscription changed, inside memo");
    return subscription;
  }, [subscription]);

  useEffect(() => {
    if (resource) {
      setSubscription({
        ...getSubscription,
        member: resource.id,
        member_names: resource.firstname + " " + resource.lastname,
      });
    }
  }, [resource, getSubscription]);

  useEffect(() => {
    subExtraGetResourceList();
  }, [subExtraGetResourceList]);

  useEffect(() => {});
  return (
    <Paper
      sx={{
        borderRadius: (theme) => theme.spacing(0.5),
        boxShadow: (theme) => theme.shadows[5],
        padding: (theme) => theme.spacing(3),
      }}
    >
      <Typography variant="h6" mb={4}>
        {id ? "Edit subscription" : "Create subscription"}
      </Typography>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {(formik) => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="member_names"
                    label="Member"
                    disabled
                    {...formik.getFieldProps("member_names")}
                    // value={member}
                    error={
                      formik.touched.member && Boolean(formik.errors.member)
                    }
                    helperText={formik.touched.member && formik.errors.member}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      width: "100%",
                    }}
                    error={
                      formik.touched.subscription_type &&
                      Boolean(formik.errors.subscription_type)
                    }
                  >
                    <InputLabel id="subscription_type">
                      Subscription Type
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="subscription_type"
                      label="Subscription Type"
                      id="subscription_type"
                      // {...formik.getFieldProps("subscription_type")}
                      value={subscription.subscription_type}
                      onChange={handleOnChange("subscription_type")}
                    >
                      {Array.isArray(resourceList.results)
                        ? resourceList.results.map((p, i) => {
                            return (
                              <MenuItem value={p.id} key={p.id}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box sx={{ ml: 1 }}>
                                    {p.name} - {p.cost}
                                  </Box>
                                </Box>
                              </MenuItem>
                            );
                          })
                        : initialValues.subscription_type}
                    </Select>
                    <FormHelperText>
                      {formik.touched.subscription_type &&
                        formik.errors.subscription_type}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel id="subscription-duration-buttons-group-label">
                      Duration Type
                    </FormLabel>
                    {subscriptionType.is_package_deal && (
                      <RadioGroup
                        aria-labelledby="subscription-duration-radio-buttons-group-label"
                        value={
                          subscriptionType.duration_type === "M" ? true : false
                        }
                        row
                        disabled={"radioDisable"}
                        onChange={handleOnChange("is_monthly_subscription")}
                        name="is_monthly_subscription"
                      >
                        <FormControlLabel
                          value={true}
                          // value="M"
                          control={<Radio />}
                          label="Months"
                        />
                        <FormControlLabel
                          value={false}
                          // value="D"
                          control={<Radio />}
                          label="Days"
                        />
                      </RadioGroup>
                    )}
                    {!subscriptionType.is_package_deal && (
                      <RadioGroup
                        aria-labelledby="subscription-duration-radio-buttons-group-label"
                        value={subscription.is_monthly_subscription}
                        onChange={handleOnChange("is_monthly_subscription")}
                        row
                        name="is_monthly_subscription"
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Months"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Days"
                        />
                      </RadioGroup>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {subscriptionType.is_package_deal && (
                    <TextField
                      fullWidth
                      id="months"
                      label="Duration"
                      value={subscriptionType.duration}
                      onFocus={handleFocus("months")}
                      onChange={handleOnChange("months")}
                      disabled
                      error={
                        formik.touched.months && Boolean(formik.errors.months)
                      }
                      helperText={formik.touched.months && formik.errors.months}
                    />
                  )}
                  {!subscriptionType.is_package_deal && (
                    <TextField
                      fullWidth
                      id="months"
                      label="months"
                      value={subscription.months}
                      onFocus={handleFocus("months")}
                      onChange={handleOnChange("months")}
                      error={
                        formik.touched.months && Boolean(formik.errors.months)
                      }
                      helperText={formik.touched.months && formik.errors.months}
                    />
                  )}
                </Grid>

                {subExtraResourceList &&
                  subExtraResourceList.results.length > 0 && (
                    <Grid item xs={12}>
                      <FormControl component={"fieldset"} variant="standard">
                        <FormLabel component={"legend"}>
                          Add the following subscription extras to your plan?
                        </FormLabel>

                        <FormGroup>
                          {subExtraResourceList.results.map((subExtra, idx) => {
                            return (
                              <FormControlLabel
                                key={idx}
                                label={subExtra.name}
                                control={
                                  <Checkbox
                                    onChange={handleOnChange(
                                      "subscription_extras"
                                    )}
                                    name={subExtra.name
                                      .split(" ")
                                      .join("_")
                                      .toLowerCase()}
                                    value={subExtra.id}
                                  />
                                }
                              />
                            );
                          })}
                        </FormGroup>
                        <FormHelperText>
                          Each subscription extra attracts extra fees.
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  )}

                {/* <Grid item xs={12}>
                  <FormControl component={"fieldset"} variant="standard">
                    <FormLabel component={"legend"}>
                      Add the following subscription extras to your plan?
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        label="Diet"
                        control={
                          <Checkbox
                            checked={initialValues.diet_status}
                            onChange={handleOnChange("subscription_extras")}
                            name="diet"
                          />
                        }
                      />
                      <FormControlLabel
                        label="Personal Trainer"
                        control={
                          <Checkbox
                            checked={initialValues.personal_trainer_status}
                            onChange={handleOnChange("subscription_extras")}
                            name="personal_trainer"
                          />
                        }
                      />
                    </FormGroup>
                    <FormHelperText>
                      Each subscription extra attracts extra fees.
                    </FormHelperText>
                  </FormControl>
                </Grid> */}

                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        fullWidth
                        id="start_date"
                        label="Start Date"
                        value={subscription.start_date}
                        // {...formik.getFieldProps("start_date")}
                        onChange={(value) => {
                          formik.setFieldValue(
                            "start_date",
                            new Date(Date.parse(value))
                          );
                          setSubscription({
                            ...subscription,
                            start_date: new Date(Date.parse(value)),
                          });
                        }}
                        error={
                          formik.touched.start_date &&
                          Boolean(formik.errors.start_date)
                        }
                        helperText={
                          formik.touched.start_date && formik.errors.start_date
                        }
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      width: "100%",
                    }}
                    error={
                      formik.touched.payment_mode &&
                      Boolean(formik.errors.payment_mode)
                    }
                  >
                    <InputLabel id="payment_mode">Payment Mode</InputLabel>
                    <Select
                      fullWidth
                      labelId="payment_mode"
                      label="payment_mode"
                      id="payment_mode"
                      // {...formik.getFieldProps("payment_mode")}
                      value={subscription.payment_mode}
                      onChange={handleOnChange("payment_mode")}
                    >
                      {Array.isArray(paymentTypeDataList) &&
                        paymentTypeDataList.map((p) => {
                          return (
                            <MenuItem value={p.value} key={p.value}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box sx={{ ml: 1 }}>{p.label}</Box>
                              </Box>
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText>
                      {formik.touched.payment_mode &&
                        formik.errors.payment_mode}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {showRefNumField ? (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="reference_number"
                      label="Reference Number"
                      // {...formik.getFieldProps("reference_number")}
                      value={subscription.reference_number}
                      onChange={handleOnChange("reference_number")}
                      error={
                        formik.touched.reference_number &&
                        Boolean(formik.errors.reference_number)
                      }
                      helperText={
                        formik.touched.reference_number &&
                        formik.errors.reference_number
                      }
                    />
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="full_amount"
                    label="Total Cost"
                    disabled
                    // {...formik.getFieldProps("amount_paid")}
                    value={subscription.full_amount}
                    error={
                      formik.touched.full_amount &&
                      Boolean(formik.errors.full_amount)
                    }
                    helperText={
                      formik.touched.full_amount && formik.errors.full_amount
                    }
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <FormControlLabel
                    error={
                      formik.touched.allow_credit &&
                      Boolean(formik.errors.allow_credit)
                    }
                    label="Allow Credit"
                    control={
                      <Checkbox
                        id="allow_credit"
                        // {...formik.getFieldProps("amount_paid")}
                        value={initialValues.allow_credit}
                        onChange={handleOnChange("allow_credit")}
                      />
                    }
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="amount_paid"
                    label="Amount to be paid"
                    inputProps={{
                      readOnly: subscription?.allow_credit,
                    }}
                    value={subscription.amount_paid}
                    onChange={handleOnChange("amount_paid")}
                    error={
                      formik.touched.amount_paid &&
                      Boolean(formik.errors.amount_paid)
                    }
                    helperText={
                      formik.touched.amount_paid && formik.errors.amount_paid
                    }
                  />
                </Grid>

                <Grid item>
                  <Box
                    sx={{
                      display: "flex",
                      margin: (theme) => theme.spacing(1),
                      marginTop: (theme) => theme.spacing(3),
                    }}
                  >
                    <Button
                      component={Link}
                      to="/subscriptions"
                      size="medium"
                      variant="outlined"
                      sx={{ mr: 2 }}
                    >
                      Back
                    </Button>

                    <Button
                      type="submit"
                      size="medium"
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Paper>
  );
}
