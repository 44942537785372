import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Grid, TextField, Typography, Paper, Button, Box } from "@mui/material";
import * as yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import useRequestResource from "src/hooks/useRequestResource";
export default function BranchDetails() {
  const { addResource, getResource, resource, updateResource } =
    useRequestResource({
      endpoint: "branches",
      resourceLabel: "Branch",
    });
  const [initialValues, setInitialValues] = useState({
    name: "",
    location: "",
  });

  const validationSchema = yup.object({
    name: yup
      .string()
      .required("Name is required")
      .max(100, "Max length is 100"),
    location: yup
      .string()
      .required("Location is required")
      .max(100, "Max length is 100"),
  });

  const navigate = useNavigate();
  const handleSubmit = (values) => {
    if (id) {
      updateResource(id, values, () => {
        navigate("/branches");
      });
      return;
    }
    addResource(values, () => {
      navigate("/branches");
    });
  };

  const { id } = useParams();

  useEffect(() => {
    if (id) getResource(id);
  }, [id, getResource]);

  useEffect(() => {
    if (resource) {
      setInitialValues({
        name: resource.name,
        location: resource.location,
      });
    }
  }, [resource]);

  return (
    <Paper
      sx={{
        borderRadius: "2px",
        boxShadow: (theme) => theme.shadows[5],
        padding: (theme) => theme.spacing(12, 4, 3),
      }}
    >
      <Typography variant="h6" mb={4}>
        {id ? "Edit Branch" : "Create Branch"}
      </Typography>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {(formik) => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="name"
                    label="Name"
                    {...formik.getFieldProps("name")}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="location"
                    label="Location"
                    {...formik.getFieldProps("location")}
                    error={
                      formik.touched.location && Boolean(formik.errors.location)
                    }
                    helperText={
                      formik.touched.location && formik.errors.location
                    }
                  />
                </Grid>

                <Grid item>
                  <Box
                    sx={{
                      display: "flex",
                      margin: (theme) => theme.spacing(1),
                      marginTop: (theme) => theme.spacing(3),
                    }}
                  >
                    <Button
                      component={Link}
                      to="/subscription-types"
                      size="medium"
                      variant="outlined"
                      sx={{ mr: 2 }}
                    >
                      Back
                    </Button>
                    <Button type="submit" size="medium" variant="contained">
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Paper>
  );
}
