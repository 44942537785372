import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import {
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { red } from "@mui/material/colors";
import { fDateTime } from "src/utils/format-time";

export default function MessagesTable({ resourceList, handleConfirmDelete }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <TableContainer component={Paper}>
      {!matches ? (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">Message</TableCell>
              <TableCell align="left">No. Recipients</TableCell>
              <TableCell align="left">status</TableCell>
              <TableCell align="left">Time Sent</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resourceList.results.map((data, i) => {
              return (
                <TableRow key={i}>
                  <TableCell align="left">{data.message}</TableCell>
                  <TableCell align="left">{data.recipients.length}</TableCell>
                  <TableCell align="left">{data.status}</TableCell>
                  <TableCell align="left">
                    {fDateTime(data.time_sent)}
                  </TableCell>
                  <TableCell align="right">
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <IconButton
                        size="large"
                        sx={{
                          color: red[500],
                        }}
                        onClick={() => handleConfirmDelete(data.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        resourceList.results.map((data, i) => {
          return (
            <TableContainer
              component={Paper}
              key={i}
              sx={{
                marginBottom: 5,
                minWidth: 270,
              }}
              size="small"
            >
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell variant="head" align="right">
                      Message
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="subtitle1">
                        {data.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head" align="right">
                      No. Recipients
                    </TableCell>
                    <TableCell align="left">{data.recipients.length}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head" align="right">
                      Status
                    </TableCell>
                    <TableCell align="left">{data.status}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head" align="right">
                      Date Sent
                    </TableCell>
                    <TableCell align="left">
                      {fDateTime(data.time_sent)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <IconButton
                          size="large"
                          sx={{
                            color: red[500],
                          }}
                          onClick={() => handleConfirmDelete(data.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          );
        })
      )}
    </TableContainer>
  );
}

MessagesTable.propTypes = {
  resourceList: PropTypes.shape({
    results: PropTypes.array,
  }),
  handleConfirmDelete: PropTypes.func,
};
