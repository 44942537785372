import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  Paper,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { spacing } from "@mui/system";
import { CloudUpload } from "@mui/icons-material";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import useRequestResource from "src/hooks/useRequestResource";

const validationSchema = yup.object({
  username: yup
    .string()
    .required("Username is required")
    .max(100, "Max length is 100"),
  // password: yup
  //   .string()
  //   .required("Password is required")
  //   .max(100, "Max length is 100"),
  email: yup
    .string()
    .email()
    .required("email is required")
    .max(100, "Max length is 100"),
  first_name: yup
    .string()
    .required("Firstname is required")
    .max(100, "Max length is 100"),
  last_name: yup
    .string()
    .required("Lastname is required")
    .max(100, "Max length is 100"),
  phone_number: yup
    .string()
    .required("Phone number is required")
    .max(20, "Max length is 20"),
  gender: yup
    .string()
    .required("Gender is required")
    .max(6, "Max length is six"),
  branch: yup
    .string()
    .required("Branch is required")
    .max(100, "Max length is 100"),
  user_type: yup
    .string()
    .required("User type is required")
    .max(1, "Max length is six"),
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

export default function StaffDetails() {
  const choicesRequestResource = useRequestResource({
    endpoint: "staff/choices",
  });
  const getChoicesResourceList = choicesRequestResource.getResourceList;
  const { resource, getResource, addResource, updateResource } =
    useRequestResource({
      endpoint: "staff",
      resourceLabel: "Staff",
    });
  const { getResourceList, resourceList } = useRequestResource({
    endpoint: "branches",
    resourceLabel: "Branch",
  });
  const [initialValues, setInitialValues] = useState({
    username: "",
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    image_url: "",
    gender: "",
    user_type: "",
    branch: "",
  });
  const [choices, setChoices] = useState();
  const [staffImage, setStaffImage] = useState();

  const navigate = useNavigate();
  const { id } = useParams();

  const classes = useStyles();

  const handleSubmit = (values) => {
    function clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    }
    if (id) {
      // console.log(clean(values));
      updateResource(
        id,
        clean(values),
        () => {
          navigate("/staff");
        },
        "multipart/form-data"
      );
      return;
    }
    addResource(
      values,
      () => {
        // console.log("Add resource called");
        navigate("/staff");
      },
      "multipart/form-data"
    );
  };
  const handleOnChange = (name) => (event) => {
    // console.log(`${name} changed to ${event.target.value}`);
    setInitialValues({ ...initialValues, [name]: event.target.value });
  };
  const handleImageChange = (event) => {
    const newData = { ...initialValues };
    newData["image_url"] = event.target.files[0];
    setInitialValues(newData);
    setStaffImage(URL.createObjectURL(event.target.files[0]));
  };
  useEffect(() => {
    if (id) {
      getResource(id);
    }
  }, [getResource, id]);

  useEffect(() => {
    if (resource) {
      setInitialValues({
        username: resource.username,
        password: null,
        email: resource.email,
        first_name: resource.first_name,
        last_name: resource.last_name,
        phone_number: resource.phone_number,
        gender: resource.gender,
        image_url: null,
        user_type: resource.user_type,
        branch: resource.branch.id,
      });
      setStaffImage(resource.image_url);
      // console.log(resource.branch.id);
    }
  }, [resource]);

  useEffect(() => {
    getResourceList();
  }, [getResourceList]);
  useEffect(() => {
    getChoicesResourceList();
  }, [getChoicesResourceList]);
  useEffect(() => {
    setChoices(choicesRequestResource.resourceList);
  }, [choicesRequestResource.resourceList]);

  return (
    <Paper
      sx={{
        borderRadius: (theme) => theme.spacing(0.5),
        boxShadow: (theme) => theme.shadows[5],
        padding: (theme) => theme.spacing(3),
      }}
    >
      <Typography variant="h6" mb={4}>
        {id ? "Edit User" : "Create User"}
      </Typography>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {(formik) => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="username"
                    label="Username"
                    value={initialValues.username ? initialValues.username : ""}
                    onChange={handleOnChange("username")}
                    error={
                      formik.touched.username && Boolean(formik.errors.username)
                    }
                    helperText={
                      formik.touched.username && formik.errors.username
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="email"
                    label="Email"
                    value={initialValues.email ? initialValues.email : ""}
                    onChange={handleOnChange("email")}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="password"
                    label="Password"
                    type="password"
                    autoComplete="new-password"
                    value={initialValues.password ? initialValues.password : ""}
                    onChange={handleOnChange("password")}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <Divider component={Grid} />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="first_name"
                    label="Firstname"
                    value={
                      initialValues.first_name ? initialValues.first_name : ""
                    }
                    onChange={handleOnChange("first_name")}
                    error={
                      formik.touched.first_name &&
                      Boolean(formik.errors.first_name)
                    }
                    helperText={
                      formik.touched.first_name && formik.errors.first_name
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="last_name"
                    label="Lastname"
                    value={
                      initialValues.last_name ? initialValues.last_name : ""
                    }
                    onChange={handleOnChange("last_name")}
                    error={
                      formik.touched.last_name &&
                      Boolean(formik.errors.last_name)
                    }
                    helperText={
                      formik.touched.last_name && formik.errors.last_name
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="phone_number"
                    label="Phone Number"
                    value={
                      initialValues.phone_number
                        ? initialValues.phone_number
                        : ""
                    }
                    onChange={handleOnChange("phone_number")}
                    error={
                      formik.touched.phone_number &&
                      Boolean(formik.errors.phone_number)
                    }
                    helperText={
                      formik.touched.phone_number && formik.errors.phone_number
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      width: "100%",
                    }}
                    error={
                      formik.touched.gender && Boolean(formik.errors.gender)
                    }
                  >
                    <InputLabel id="gender-label">Gender</InputLabel>
                    <Select
                      fullWidth
                      labelId="gender"
                      label="Gender"
                      value={initialValues.gender ? initialValues.gender : ""}
                      onChange={handleOnChange("gender")}
                      id="gender"
                    >
                      {choices &&
                        choices.results &&
                        choices.results?.gender?.map((val, idx) => {
                          return (
                            <MenuItem value={val[0]} key={idx}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box sx={{ ml: 1 }}>{val[1]}</Box>
                              </Box>
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText>
                      {formik.touched.gender && formik.errors.gender}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <Divider flexItem />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      width: "100%",
                    }}
                    error={
                      formik.touched.user_type &&
                      Boolean(formik.errors.user_type)
                    }
                  >
                    <InputLabel id="branch-label">User Type</InputLabel>
                    <Select
                      fullWidth
                      labelId="user_type"
                      label="User Type"
                      value={
                        initialValues.user_type ? initialValues.user_type : ""
                      }
                      id="user_type"
                      onChange={handleOnChange("user_type")}
                    >
                      {choices &&
                        choices.results &&
                        choices.results?.user_types?.map((val, idx) => {
                          return (
                            <MenuItem value={val[0]} key={idx}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box sx={{ ml: 1 }}>{val[1]}</Box>
                              </Box>
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText>
                      {formik.touched.user_type && formik.errors.user_type}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      width: "100%",
                    }}
                    error={
                      formik.touched.branch && Boolean(formik.errors.branch)
                    }
                  >
                    <InputLabel id="branch-label">Branch</InputLabel>
                    <Select
                      fullWidth
                      labelId="branch"
                      label="Branch"
                      id="branch"
                      value={initialValues.branch ? initialValues.branch : ""}
                      onChange={handleOnChange("branch")}
                    >
                      {Array.isArray(resourceList.results) &&
                        resourceList.results.map((branch, idx) => {
                          return (
                            <MenuItem value={branch.id} key={idx}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box sx={{ ml: 1 }}>{branch.name}</Box>
                              </Box>
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText>
                      {formik.touched.branch && formik.errors.branch}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.root}>
                    <TextField
                      fullWidth
                      accept="image/*"
                      id="staff_img"
                      type="file"
                      className={classes.input}
                      onChange={handleImageChange}
                      error={
                        formik.touched.image_url &&
                        Boolean(formik.errors.image_url)
                      }
                      helperText={
                        formik.touched.image_url && formik.errors.image_url
                      }
                    />
                    <label htmlFor="staff_img">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        startIcon={<CloudUpload />}
                      >
                        Upload User Image
                      </Button>
                    </label>
                  </div>
                  <div>
                    {staffImage && (
                      <img
                        src={staffImage}
                        width={200}
                        height={200}
                        alt=""
                        style={{
                          marginTop: 5,
                        }}
                      />
                    )}
                  </div>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      display: "flex",
                      margin: (theme) => theme.spacing(1),
                      marginTop: (theme) => theme.spacing(3),
                    }}
                  >
                    <Button
                      component={Link}
                      to="/staff"
                      size="medium"
                      variant="outlined"
                      sx={{ mr: 2 }}
                    >
                      Back
                    </Button>

                    <Button
                      type="submit"
                      size="medium"
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Paper>
  );
}
