import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import {
  Box,
  TextField,
  Grid,
  Paper,
  Typography,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
// import Divider from "@mui/material/Divider";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import useRequestResource from "src/hooks/useRequestResource";

const validationSchema = yup.object({
  name: yup.string().required("name is required").max(100, "Max length is 100"),
  description: yup
    .string()
    .required("Description is required")
    .max(1000, "Max length is 500"),
  amount: yup.number().min(1).required("Amount is required"),
});

export default function SubscriptionFeesDetails() {
  const { resource, getResource, addResource, updateResource } =
    useRequestResource({
      endpoint: "registration-fees",
      resourceLabel: "Registration Fees",
    });

  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    amount: "",
    use_in_registration: false,
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const handleSubmit = (values) => {
    if (id) {
      updateResource(id, values, () => {
        navigate("/registration-fees");
      });
      return;
    }
    addResource(values, () => {
      navigate("/registration-fees");
    });
  };

  const handleChange = (name) => (event) => {
    let vals = initialValues;
    if (name === "use_in_registration") {
      setInitialValues({
        ...vals,
        [name]: !initialValues.use_in_registration,
      });
      return;
    }
    setInitialValues({
      ...vals,
      [name]: event.target.value,
    });
  };
  useEffect(() => {
    if (id) {
      getResource(id);
    }
  }, [getResource, id]);

  useEffect(() => {
    if (resource) {
      setInitialValues({
        name: resource.name,
        description: resource.description,
        amount: resource.amount,
        use_in_registration: resource.use_in_registration,
      });
    }
  }, [resource]);

  return (
    <Paper
      sx={{
        borderRadius: (theme) => theme.spacing(0.5),
        boxShadow: (theme) => theme.shadows[5],
        padding: (theme) => theme.spacing(3),
      }}
    >
      <Typography variant="h6" mb={4}>
        {id ? "Edit registration fee" : "Create registration fee"}
      </Typography>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {(formik) => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="name"
                    label="Name"
                    onChange={handleChange("name")}
                    value={initialValues.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    id="description"
                    label="Description"
                    onChange={handleChange("description")}
                    rows={3}
                    value={initialValues.description}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="amount"
                    label="Amount"
                    onChange={handleChange("amount")}
                    value={initialValues.amount}
                    error={
                      formik.touched.amount && Boolean(formik.errors.amount)
                    }
                    helperText={formik.touched.amount && formik.errors.amount}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component={"fieldset"} variant="standard">
                    <FormLabel component={"legend"}>
                      Apply fee during registration?
                    </FormLabel>
                    <FormControlLabel
                      label="Yes"
                      control={
                        <Checkbox
                          checked={initialValues.use_in_registration}
                          onChange={handleChange("use_in_registration")}
                          id="use_in_registration"
                        />
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      display: "flex",
                      margin: (theme) => theme.spacing(1),
                      marginTop: (theme) => theme.spacing(3),
                    }}
                  >
                    <Button
                      component={Link}
                      to="/registration-fees"
                      size="medium"
                      variant="outlined"
                      sx={{ mr: 2 }}
                    >
                      Back
                    </Button>

                    <Button
                      type="submit"
                      size="medium"
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Paper>
  );
}
