import React from "react";
import { FormControl, Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const initialValues = {
  gender: "All",
  search: "",
};

export default function Filters({ onSubmit }) {
  const handleSubmit = (values) => {
    onSubmit(values);
  };

  const theme = useTheme();
  const isBelowMedium = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      enableReinitialize
    >
      {(formik) => {
        return (
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <FormControl
                sx={{
                  width: isBelowMedium ? "100%" : "80%",
                  marginRight: (theme) => theme.spacing(1),
                  marginBottom: (theme) => theme.spacing(2),
                }}
                variant="outlined"
              >
                <TextField
                  size="small"
                  id="search"
                  label="Search"
                  type="search"
                  {...formik.getFieldProps("search")}
                />
              </FormControl>

              <Box sx={{ marginBottom: (theme) => theme.spacing(2) }}>
                <Button
                  type="submit"
                  size="medium"
                  variant="contained"
                  color="primary"
                >
                  Search
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
}

Filters.propTypes = {
  onSubmit: PropTypes.func,
};
